import React, { useState, useEffect } from 'react';
import _orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import ModalMD from './ModalMD';
import ModalInspect from './ModalInspect';
import RejectData from '../../widgets/RejectData';
import ModalFinal from './ModalFinal';
import ProcessData from '../../widgets/ProcessData';
import reactCSS from 'reactcss';
import Select from 'react-select';
const findFromList = (list, key, value) => {
  if (list && list.length) {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (item[key] && item[key].id === parseInt(value, 10)) {
        return item[key];
      }
    }
  }

  return false;
};
const findListData = (optionsInspect, name) => {
  const data =
    optionsInspect && optionsInspect.length > 0
      ? find(optionsInspect, (item) => item.name === name).list
      : [];

  return data;
};
const DetailTask = (props) => {
  const {
    tasks,
    inspection,
    user,
    photosSetting,
    resetInspect,
    listImages,
    qcPassed,
    setshowValidateSuccess,
  } = props;
  let { listSteps } = props;

  listSteps = listSteps.map((step) => {
    const tasksStep = tasks.filter((task) => task.photoCode === step.photoCode);
    return {
      ...step,
      tasks: tasksStep,
    };
  });

  const [listReject, setlistReject] = useState([]);
  const [listReport, setlistReport] = useState([]);
  const [taskId, settaskId] = useState();
  const [showListReject, setshowListReject] = useState(false);
  const [showlistReport, setshowlistReport] = useState(false);
  const [showModalInspect, setshowModalInspect] = useState(false);
  const [showModalImage, setshowModalImage] = useState(false);
  const [dataTask, setdataTask] = useState({});
  const [dataValidate, setdataValidate] = useState({});
  const [dataUpdateVehicle, setdataUpdateVehicle] = useState({});
  const statusInspection = inspection?.status || '';

  const autoInspectAt = inspection.autoInspectAt
    ? new Date(inspection.autoInspectAt)
    : null;

  const endTime = autoInspectAt ? autoInspectAt.getTime() + 60000 : null;
  const time = new Date();
  const diff = endTime ? endTime - time.getTime() : null;
  const inSeconds = diff && diff > 0 ? Math.round(diff / 1000) : 0;
  const themeName = inspection?.inspectionThemes?.name ?? 'DEFAULT';

  const damage_table = inspection?.options?.damage_table ?? '';
  const itemReject = [];
  const itemReport = [];

  const qc_only_accept = inspection?.options?.qc_only_accept ?? null;
  const skip_vehicle_record_check =
    inspection?.options?.skip_vehicle_record_check ?? null;
  const skip_vehicle_data_check =
    inspection?.options?.skip_vehicle_data_check ?? null;
  const reject_vin_not_from_vehicle =
    inspection?.options?.reject_vin_not_from_vehicle ?? null;
  const qc_on_having_odometer =
    inspection?.options?.qc_on_having_odometer ?? null;

  const vehicle = inspection?.vehicle ?? null;
  const decode = inspection?.options?.decode ?? null;
  const dataVehicle = vehicle?.data ?? null;

  //
  const inspector = inspection?.inspector ?? '';
  const qa = inspection?.qa ?? '';
  const manager = inspection?.manager ?? '';
  const holding = inspection?.holding ?? false;
  const role = user?.role ?? 'AGENT';
  const username = user?.username ?? '';
  let showButtonSubmitVehicle = false;
  if (['QA', 'AGENT'].includes(role)) {
    if (username === inspector) {
      showButtonSubmitVehicle = true;
    }
    if (username === qa) {
      showButtonSubmitVehicle = true;
    }
    if (holding) {
      showButtonSubmitVehicle = false;
    }
    if (manager) {
      showButtonSubmitVehicle = false;
    }
  }
  if (['SUPER', 'TRAINER', 'ADMIN'].includes(role)) {
    showButtonSubmitVehicle = true;
  }
  if (
    [
      'INSPECTED',
      'COMPLETED',
      'FINISHED',
      'VIN-CONFIRM',
      'VIN-CHECKING',
    ].includes(inspection.status)
  ) {
    showButtonSubmitVehicle = false;
  }

  //
  const inspectWheel = () => {
    const photosSetting04 = photosSetting?.find(
      (item) => item.photoCode === '04',
    );
    const optionInspectWheel = photosSetting04?.optionsInspect ?? [];
    const dataWheels = dataVehicle?.wheels ?? null;
    const wheel_front_left = dataWheels?.wheel_front_left ?? '';
    const wheel_front_right = dataWheels?.wheel_front_right ?? '';
    const wheel_rear_left = dataWheels?.wheel_rear_left ?? '';
    const wheel_rear_right = dataWheels?.wheel_rear_right ?? '';
    let listMaterialWheel = findListData(optionInspectWheel, 'wheel_material');
    const frontLeft = [];
    const rearLeft = [];
    const frontRight = [];
    const rearRight = [];
    for (let i = 0; i < listMaterialWheel.length; i++) {
      frontLeft.push({
        value: listMaterialWheel[i].name,
        label: listMaterialWheel[i].description,
        name: 'wheel_front_left',
      });
      rearLeft.push({
        value: listMaterialWheel[i].name,
        label: listMaterialWheel[i].description,
        name: 'wheel_rear_left',
      });
      frontRight.push({
        value: listMaterialWheel[i].name,
        label: listMaterialWheel[i].description,
        name: 'wheel_front_right',
      });
      rearRight.push({
        value: listMaterialWheel[i].name,
        label: listMaterialWheel[i].description,
        name: 'wheel_rear_right',
      });
    }
    return (
      <div className="wheel">
        <div
          style={{ fontSize: '14px' }}
          className="badge badge-dark d-inline text-head text-uppercase "
        >
          Wheel Front Left
        </div>
        {wheel_front_left && (
          <div className="ml-2 d-inline">
            <span className="ml-1">{wheel_front_left}</span>
          </div>
        )}
        <Select
          options={frontLeft}
          onChange={(e) => handleChangeVehicle(e)}
          className="react-select-container wheel mt-1 mb-1"
          classNamePrefix="react-select"
        />
        <div
          style={{ fontSize: '14px' }}
          className="badge badge-dark d-inline text-head text-uppercase"
        >
          Wheel Rear Left
        </div>
        {wheel_rear_left && (
          <div className="ml-2 d-inline">
            <span className="ml-1">{wheel_rear_left}</span>
          </div>
        )}
        <Select
          options={rearLeft}
          onChange={(e) => handleChangeVehicle(e)}
          className="react-select-container wheel mt-1 mb-1"
          classNamePrefix="react-select"
        />
        <div
          style={{ fontSize: '14px' }}
          className="badge badge-dark d-inline text-head text-uppercase"
        >
          Wheel Front right
        </div>
        {wheel_front_right && (
          <div className="ml-2 d-inline">
            <span className="ml-1">{wheel_front_right}</span>
          </div>
        )}
        <Select
          options={frontRight}
          onChange={(e) => handleChangeVehicle(e)}
          className="react-select-container wheel mt-1 mb-1"
          classNamePrefix="react-select"
        />
        <div
          style={{ fontSize: '14px' }}
          className="badge badge-dark d-inline text-head text-uppercase"
        >
          Wheel Rear right
        </div>
        {wheel_rear_right && (
          <div className="ml-2 d-inline">
            <span className="ml-1">{wheel_rear_right}</span>
          </div>
        )}
        <Select
          options={rearRight}
          onChange={(e) => handleChangeVehicle(e)}
          className="react-select-container wheel mt-1"
          classNamePrefix="react-select"
        />
      </div>
    );
  };

  const inspectExtColor = () => {
    const extColors = dataVehicle.vehicle_ext_colors || [];
    const extColor = findFromList(extColors, 'ext_color', dataVehicle?.ext_id);
    if (extColors.length > 0) {
      return (
        <div className="ext">
          <div className="data">
            <div
              style={{ fontSize: '16px' }}
              className="badge badge-dark d-inline text-head text-uppercase d-inline"
            >
              Ext
            </div>
            {extColor && (
              <div className="ml-1 mb-1 d-inline-block">
                <div
                  className="d-flex"
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <div className="data text-head mr-1 ml-1">
                    ({extColor.color_code ? extColor.color_code : ''})
                    {extColor.generic_name ? extColor.generic_name : ''}
                  </div>
                  <div
                    className="color"
                    style={{
                      width: '76px',
                      height: '34px',
                      border: '2px solid #fff',
                      background: `#${
                        extColor.hex_value ? extColor.hex_value : ''
                      }`,
                      borderRadius: '15px',
                    }}
                  ></div>
                </div>
              </div>
            )}
          </div>

          <div className="group-color">
            <div className="list-color">
              {extColors.map((keyColor, indexColor) => {
                let styles = reactCSS({
                  default: {
                    color: {
                      background: `#${keyColor.ext_color.hex_value}`,
                    },
                  },
                });
                const id = keyColor?.ext_color?.id ?? '';
                let active = false;

                if (dataUpdateVehicle.ext_id === id) {
                  active = true;
                }

                return (
                  <div className="item-color mr-2" key={indexColor}>
                    <div className="child-item text-head">
                      {keyColor.ext_color.hex_value ? (
                        <span>
                          {keyColor.ext_color.color_code}
                          {keyColor.ext_color.generic_name
                            ? `(${keyColor.ext_color.generic_name})`
                            : null}
                        </span>
                      ) : (
                        <span>
                          {keyColor.ext_color.color_code}(
                          {keyColor.ext_color.color_name})
                        </span>
                      )}
                      <input
                        type="radio"
                        className="custom-control-input"
                        id={
                          keyColor.ext_color.hex_value
                            ? `int${keyColor.ext_color.id}`
                            : `ext${keyColor.ext_color.id}`
                        }
                        onClick={(e) => handleChangeVehicle(e)}
                        name="ext_id"
                        value={keyColor.ext_color.id}
                      />
                      <label
                        key={indexColor}
                        id={
                          keyColor.ext_color.hex_value
                            ? `int${keyColor.ext_color.id}`
                            : `ext${keyColor.ext_color.id}`
                        }
                        style={styles.color}
                        className={`${active ? 'active-color' : ''}`}
                        htmlFor={
                          keyColor.ext_color.hex_value
                            ? `int${keyColor.ext_color.id}`
                            : `ext${keyColor.ext_color.id}`
                        }
                      >
                        <span>{keyColor.ext_color.color_name}</span>
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
    return null;
  };
  const inspectTrim = () => {
    let selectTrims = [];
    let selectOptionsTrim = [];
    let checkSource = false;
    const modelInspected = dataVehicle?.model ?? null;
    const trimInspected = dataVehicle?.trim_id ?? null;
    const optionInspected = dataVehicle?.option_id ?? null;
    let dataTrimInspected = null;
    let dataOptionInspected = null;
    if (decode === 'kbb_us') {
      const listTrims = [];
      for (let i = 0; i < listTrims.length; i++) {
        const trim = listTrims[i];
        selectTrims.push({
          value: trim.id,
          label: trim.name,
          name: 'trim_id',
        });
      }
      dataTrimInspected = selectTrims.find(
        (item) => item.value === trimInspected,
      );
      return (
        <div className="information text-head">
          <div className="data-inspect">
            <div
              style={{ fontSize: '16px' }}
              className="badge badge-dark d-inline text-head text-uppercase"
            >
              Model
            </div>
            {modelInspected && (
              <div className="mb-1 ml-2 d-inline">
                <span className="ml-1">{modelInspected}</span>
              </div>
            )}
            {dataTrimInspected && (
              <div className="mb-1 ml-2 d-inline">
                <span className="ml-1">{dataTrimInspected.label}</span>
              </div>
            )}
            <input
              style={{
                transition: 'all .3s ease',
              }}
              autoComplete="off"
              type="text"
              className="form-control mt-1 mb-1"
              name="model"
              onChange={(e) => handleChangeVehicle(e)}
            />
            <div
              style={{ fontSize: '16px' }}
              className="badge badge-dark d-inline text-head text-uppercase"
            >
              Trim
            </div>
            <Select
              options={selectTrims}
              onChange={(e) => handleChangeVehicle(e)}
              className="react-select-container mt-1"
              classNamePrefix="react-select"
            />
          </div>
        </div>
      );
    } else {
      const vehicle_trims_pack = dataVehicle?.vehicle_trims_pack ?? [];
      const vehicle_trims = dataVehicle?.vehicle_trims ?? [];
      if (vehicle_trims_pack) {
        for (let i = 0; i < vehicle_trims_pack.length; i++) {
          if (vehicle_trims_pack[i].trim && vehicle_trims_pack[i].trim.source) {
            checkSource = true;
            if (vehicle_trims_pack[i].trim.transmission_type_id === 1) {
              selectTrims.unshift({
                value: vehicle_trims_pack[i].trim.id,
                label:
                  `${vehicle_trims_pack[i].trim.name} |` +
                  ` ${
                    vehicle_trims_pack[i].trim.transmission_type_id === 1
                      ? 'Auto'
                      : 'Manual'
                  } |` +
                  `${
                    vehicle_trims_pack[i].trim.engine
                      ? vehicle_trims_pack[i].trim.engine
                      : ''
                  }`,
                source: vehicle_trims_pack[i].trim.source,
                name: 'trim_id',
              });
            } else {
              selectTrims.push({
                value: vehicle_trims_pack[i].trim.id,
                label:
                  `${vehicle_trims_pack[i].trim.name} |` +
                  ` ${
                    vehicle_trims_pack[i].trim.transmission_type_id === 1
                      ? 'Auto'
                      : 'Manual'
                  } |` +
                  `${
                    vehicle_trims_pack[i].trim.engine
                      ? vehicle_trims_pack[i].trim.engine
                      : ''
                  }`,
                source: vehicle_trims_pack[i].trim.source,
                name: 'trim_id',
              });
            }

            dataTrimInspected = selectTrims.find(
              (item) => item.value === trimInspected,
            );
          } else {
            selectOptionsTrim.push({
              trim_id: vehicle_trims_pack[i].trim.trim_id,
              label: vehicle_trims_pack[i].full_name,
              value: vehicle_trims_pack[i].full_name,
              name: 'pack',
              option_id:
                vehicle_trims_pack[i].option &&
                vehicle_trims_pack[i].option.option_id
                  ? vehicle_trims_pack[i].option.option_id
                  : '',
            });
            dataOptionInspected = selectOptionsTrim.find(
              (item) => item.option_id === optionInspected,
            );
          }
        }
      }
      if (vehicle_trims.length && vehicle_trims_pack.length && !checkSource) {
        for (let i = 0; i < vehicle_trims.length; i++) {
          selectTrims.push({
            value: vehicle_trims[i].trim.id,
            label:
              ` ${
                vehicle_trims[i].trim.trim
                  ? `${vehicle_trims[i].trim.trim} |`
                  : ``
              } ` +
              ` ${vehicle_trims[i].trim.name} |` +
              ` ${vehicle_trims[i].trim.transmission} |` +
              ` ${
                vehicle_trims[i].trim.engine_type
                  ? vehicle_trims[i].trim.engine_type
                  : ``
              }`,
            name: 'trim_id',
          });
        }
        dataTrimInspected = selectTrims.find(
          (item) => item.value === trimInspected,
        );
      } else if (vehicle_trims && !checkSource) {
        for (let i = 0; i < vehicle_trims.length; i++) {
          selectTrims.push({
            value: vehicle_trims[i].trim.id,
            label:
              ` ${
                vehicle_trims[i].trim.trim
                  ? `${vehicle_trims[i].trim.trim} |`
                  : ``
              } ` +
              ` ${vehicle_trims[i].trim.name} | ` +
              ` ${vehicle_trims[i].trim.transmission} | ` +
              ` ${
                vehicle_trims[i].trim.engine_type
                  ? vehicle_trims[i].trim.engine_type
                  : ``
              }`,
            name: 'trim_id',
          });
        }
        dataTrimInspected = selectTrims.find(
          (item) => item.value === trimInspected,
        );
      }
      if (!checkSource) {
        return (
          <div className="information text-head">
            <div className="data-inspect">
              <div
                style={{ fontSize: '14px' }}
                className="badge badge-dark d-inline text-head text-uppercase"
              >
                Model
              </div>
              {modelInspected && (
                <div className="mb-1 ml-2 d-inline">
                  <span className="ml-1">{modelInspected}</span>
                </div>
              )}
              <input
                style={{
                  transition: 'all .3s ease',
                }}
                autoComplete="off"
                type="text"
                className="form-control mt-1 mb-1"
                name="model"
                onChange={(e) => handleChangeVehicle(e)}
              />
              <div
                style={{ fontSize: '14px' }}
                className="badge badge-dark d-inline text-head text-uppercase"
              >
                Trim
              </div>
              {dataTrimInspected && (
                <div className="mb-1 ml-2 d-inline">
                  <span className="ml-1">{dataTrimInspected.label}</span>
                </div>
              )}
              <Select
                options={selectTrims}
                onChange={(e) => handleChangeVehicle(e)}
                className="react-select-container mt-1"
                classNamePrefix="react-select"
              />
            </div>
            <div className="data-inspect mt-1">
              <div
                style={{ fontSize: '16px' }}
                className="badge badge-dark d-inline text-head text-uppercase"
              >
                Package
              </div>
              {dataOptionInspected && (
                <div className="mb-1 ml-2 d-inline">
                  <span className="ml-1">{dataOptionInspected.label}</span>
                </div>
              )}
              <Select
                options={selectOptionsTrim}
                onChange={(e) => handleChangeVehicle(e)}
                className="react-select-container mt-1"
                classNamePrefix="react-select"
              />
            </div>
          </div>
        );
      }
      if (checkSource) {
        return (
          <div className="information text-head">
            <div className="data-inspect">
              <div
                style={{ fontSize: '16px' }}
                className="badge badge-dark d-inline text-head text-uppercase"
              >
                Model
              </div>
              {modelInspected && (
                <div className="mb-1 ml-2 d-inline">
                  <span className="ml-1">{modelInspected}</span>
                </div>
              )}
              <input
                style={{
                  transition: 'all .3s ease',
                }}
                autoComplete="off"
                type="text"
                className="form-control mt-1 mb-1"
                name="model"
                onChange={(e) => handleChangeVehicle(e)}
              />
              {dataTrimInspected && (
                <div className="mb-1 ml-2 d-inline">
                  <span className="ml-1">{dataTrimInspected.label}</span>
                </div>
              )}
              <div
                style={{ fontSize: '16px' }}
                className="badge badge-dark d-inline text-head text-uppercase"
              >
                Trim
              </div>
              <Select
                options={selectTrims}
                onChange={(e) => handleChangeVehicle(e)}
                className="react-select-container mt-1"
                classNamePrefix="react-select"
              />
            </div>
          </div>
        );
      }
    }
  };
  const handleChangeVehicle = (e) => {
    let name = e.target ? e.target.name : e.name;
    let value = e.target ? e.target.value : e.value;
    let source = e.target ? e.target.source : e.source ? e.source : '';

    let data = {};
    if (name === 'model') {
      data[`${name}`] = value.toUpperCase();
    }
    if (name === 'trim_id') {
      data[`${name}`] = value;
    }
    if (name === 'pack') {
      const option_id = e.option_id ? e.option_id : null;
      const trim_id = e.trim_id ? e.trim_id : null;
      data = {
        ...dataUpdateVehicle,
        option_id: option_id,
        trim_id: trim_id,
      };
    }
    if (name === 'ext_id') {
      data[`${name}`] = parseInt(value);
    }
    if (
      [
        'wheel_front_left',
        'wheel_front_right',
        'wheel_rear_left',
        'wheel_rear_right',
      ].includes(name)
    ) {
      data['wheels'] = {
        ...dataUpdateVehicle['wheels'],
        [name]: value,
      };
    }
    if (source) {
      data = {
        ...dataUpdateVehicle,
        ...data,
        source: source,
      };
    }
    setdataUpdateVehicle({ ...dataUpdateVehicle, ...data });
  };
  for (let i = 0; i < listReport.length; i++) {
    const item = listReport[i];
    itemReport.push(
      <option key={`report_${i}`} value={`${item.name}`}>
        {item.name.replace(/_/g, ' ')}
      </option>,
    );
  }
  for (let i = 0; i < listReject.length; i++) {
    const item = listReject[i];
    if (
      qcPassed &&
      ['DEFAULT', 'DEF-1', 'DEF-2', 'DEF-3'].includes(inspection.group)
    ) {
      continue;
    }
    if (
      !qcPassed &&
      ['mismatched_record', 'mismatched_vehicle'].includes(item.name) &&
      ['AMZ', 'AMZ-1', 'AMZ-2', 'AMZ-3'].includes(inspection.group)
    ) {
      continue;
    }
    if (
      qcPassed &&
      !['mismatched_record', 'mismatched_vehicle'].includes(item.name)
    ) {
      continue;
    }
    if (skip_vehicle_data_check && item.name === 'mismatched_vehicle') {
      continue;
    }
    if (
      dataVehicle &&
      dataVehicle.odom_reading &&
      dataVehicle.odom_unit &&
      qc_on_having_odometer &&
      ['mismatched_vehicle', 'no_odometer'].includes(item.name)
    ) {
      continue;
    }
    if (skip_vehicle_record_check && item.name === 'mismatched_record') {
      continue;
    }
    if (
      !reject_vin_not_from_vehicle &&
      item.name === 'vin_not_from_vehicle' &&
      themeName !== 'CAPT'
    ) {
      continue;
    }
    itemReject.push(
      <option key={`reject_${i}`} value={`${item.name}`}>
        {item.name.replace(/_/g, ' ')}
      </option>,
    );
  }
  useEffect(() => {
    if (!showModalInspect) {
      resetInspect();
    }
  }, []);
  const showModalFinal = (task, url, agent, notGen, final) => {
    setdataTask({
      task: task,
      url: url,
      agent: agent ? agent : false,
      notGen: notGen ? notGen : false,
      final: final ? final : false,
      inspection: inspection,
    });
    let dataUpdate = {
      photo_id: task?.dataHitl?.photo_id ?? 0,
      photo_code: task?.dataHitl?.photo_code ?? 0,
    };
    const avn = task?.dataHitl?.avn_inspect ?? '';
    const avn_feedback = task?.dataHitl?.avn_feedback ?? 0;
    const vlp = task?.dataHitl?.vlp_inspect ?? '';
    const vlp_feedback = task?.dataHitl?.vlp_feedback ?? 0;
    if (avn) {
      dataUpdate = {
        ...dataUpdate,
        avn: avn,
      };
    }
    if (avn_feedback) {
      dataUpdate = {
        ...dataUpdate,
        avn_feedback: avn_feedback,
      };
    }
    if (vlp) {
      dataUpdate = {
        ...dataUpdate,
        vlp: vlp,
      };
    }
    if (vlp_feedback) {
      dataUpdate = {
        ...dataUpdate,
        vlp_feedback: vlp_feedback,
      };
    }
    setdataValidate({
      ...dataUpdate,
    });
    setshowModalImage(true);
  };
  const onAcceptTask = (taskId) => {
    if (!taskId) {
      return false;
    }
    const data = {};
    data['message'] = 'accepted';
    props.acceptTask(taskId, data);
  };
  // Flow Invalid photos from AI
  const onChangeValidatePhoto = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (dataValidate[name] === value) {
      value = 0;
    }
    const data = {
      ...dataValidate,
      [name]: value,
    };
    if (value === 0 || value === '') {
      delete data[name];
    }
    setdataValidate(data);
  };

  const confirmValidatePhoto = async () => {
    const sessionID = inspection?.sessionID ?? '';
    const data = {
      dataValidate,
    };

    const res = await props.validatePhoto(sessionID, data);
    setdataValidate({});
    setshowModalImage(false);
    if (res.allDone) {
      setshowValidateSuccess();
    }
  };

  const hideModalFinal = () => {
    setdataValidate({});
    setshowModalImage(false);
  };
  //
  const showReport = (photoCode, taskId) => {
    if (!photoCode || !taskId) {
      return false;
    }
    const taskSettings =
      photosSetting &&
      photosSetting.reduce((obj, item) => {
        obj[item.photoCode] = item;
        return obj;
      }, {});
    const listReports =
      taskSettings[`${photoCode}`] && taskSettings[`${photoCode}`].rejects
        ? taskSettings[`${photoCode}`].rejects
        : null;

    setshowlistReport(true);
    setlistReport(listReports);
    settaskId(taskId);
  };
  const showReject = (photoCode, taskId) => {
    if (!photoCode || !taskId) {
      return false;
    }
    const taskSettings =
      photosSetting &&
      photosSetting.reduce((obj, item) => {
        obj[item.photoCode] = item;
        return obj;
      }, {});
    const listReject =
      taskSettings[`${photoCode}`] && taskSettings[`${photoCode}`].rejects
        ? taskSettings[`${photoCode}`].rejects
        : null;

    setshowListReject(true);
    setlistReject(listReject);
    settaskId(taskId);
  };
  const showInspect = (taskId) => {
    if (!taskId) {
      return false;
    }
    settaskId(taskId);
    setshowModalInspect(true);
  };
  const reportImage = (e) => {
    e.preventDefault();
    const taskId = e.target.taskId.value;
    const reason = e.target.reason.value;

    if (!taskId || !reason) {
      return false;
    }
    const data = {};
    data['message'] = reason;
    setshowlistReport(false);
    props.reportImage(taskId, data);
  };
  const rejectTask = (e) => {
    e.preventDefault();
    const taskId = e.target.taskId.value;
    const status = e.target.status.value;

    if (!taskId || !status) {
      return false;
    }
    const data = {};
    data['message'] = status;
    setshowListReject(false);
    props.rejectTask(taskId, data);
  };
  return (
    <div className="list_photoCode">
      {qc_only_accept ? (
        <div className="mb-2">
          <div
            className="badge badge-success text-uppercase mr-2 mb-1"
            style={{
              fontWeight: 'bold',
              fontSize: '15px',
            }}
          >
            <span className="text-head text-white">Accept All task</span>
          </div>
        </div>
      ) : null}

      <div className="mb-2">
        {autoInspectAt && (inSeconds || inSeconds === 0) ? (
          <div className="mb-2">
            <div
              className="badge badge-primary text-uppercase mr-2 mb-1"
              style={{
                fontWeight: 'bold',
                fontSize: '15px',
              }}
            >
              <span className="text-head text-white">Auto</span>
            </div>
            <div
              className="badge badge-primary text-uppercase  mb-1"
              style={{
                fontWeight: 'bold',
                fontSize: '15px',
              }}
            >
              <span className="text-head text-white">{inSeconds}</span>
            </div>
          </div>
        ) : null}
      </div>

      <div className="list_images">
        {listImages.length > 0 ? (
          <div
            className="information_image mt-2"
            style={{
              borderRadius: '30px',
              padding: '0px 2px',
            }}
          >
            {listImages.map((item, index) => {
              let hitl = false;
              const dataHitl = item?.dataHitl ?? false;
              if (dataHitl.avn || dataHitl.vlp) {
                hitl = true;
              }
              const invalidDone = dataHitl?.done ?? false;
              const invalid = dataHitl?.invalid ?? false;
              const urlDynamic = process.env.REACT_APP_PAVE_DYNAMIC_PHOTO_ROOT;
              const original = item?.url_builder?.o ?? null;
              const bucket = item?.url_builder?.b ?? null;
              const widthThumb = 540;
              const heightThumb = 304;
              const linkUrlPhoto = item.url_builder
                ? urlDynamic + `?` + `&b=${bucket}` + `&o=${original}`
                : item.image_cap;
              const linkUrlPhotoWebp = item.url_builder
                ? urlDynamic +
                  `?` +
                  `&b=${bucket}` +
                  `&o=${original}` +
                  `&p=100`
                : `${item.image_cap}.webp`;
              const thumbnail = item.url_builder
                ? linkUrlPhoto + `&w=${widthThumb}` + `&h=${heightThumb}`
                : `${item.image_cap}.webp`;
              return (
                <div
                  className="mr-1 mb-1"
                  style={{
                    width: '14%',
                    display: 'inline-block',
                    position: 'relative',
                    border:
                      hitl && invalid
                        ? '3px solid red'
                        : hitl && !invalid
                        ? '3px solid green'
                        : '',
                    borderRadius: '10px',
                  }}
                  key={`imageList_${index}`}
                >
                  <div
                    className="photo"
                    style={{
                      position: 'absolute',
                      top: '-7px',
                      left: '-7px',
                      background: '#000',
                      padding: '1px 3px',
                      borderRadius: '5px',
                      color: '#fff',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {item.photoCode}
                  </div>
                  {item.statusImg === 'BAD' ? (
                    <div
                      className="statusImg"
                      style={{
                        position: 'absolute',
                        top: '-7px',
                        right: '-7px',
                        background: 'red',
                        padding: '1px 3px',
                        color: '#fff',
                        borderRadius: '5px',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      {item.statusImg}
                    </div>
                  ) : null}
                  {hitl ? (
                    <div>
                      <div
                        style={{
                          position: 'absolute',
                          top: '-15px',
                          left: '40%',
                        }}
                        className="invalid"
                      >
                        <div
                          className={`badge badge-${
                            invalid ? 'danger' : 'success'
                          } text-head`}
                        >
                          {invalid ? 'INVALID' : 'VALID'}
                        </div>
                      </div>
                      {invalidDone ? (
                        <div
                          style={{
                            position: 'absolute',
                            top: '-10px',
                            right: '0',
                          }}
                          className="badge badge-pill badge-success"
                        >
                          <i className=" fas fa-check-circle"></i>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  {item.image_cap ? (
                    <img
                      onClick={() => {
                        showModalFinal(item, linkUrlPhotoWebp);
                      }}
                      style={{
                        width: '100%',
                        borderRadius: '10px',
                        cursor: 'pointer',
                      }}
                      src={`${thumbnail}`}
                      alt="img-webp"
                    ></img>
                  ) : (
                    <div
                      className="not_image"
                      style={{
                        display: 'flex',
                        borderRadius: '10px',
                        width: '100%',
                        height: '150px',
                        background: '#000',
                        color: '#fff',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: 'bold',
                      }}
                    >
                      <span>No Image</span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
      {dataVehicle ? (
        <div className="d-flex">
          <div className="inspect-color" style={{ width: '45%' }}>
            {inspectExtColor()}
          </div>
          <div className="inspect-trim ml-1" style={{ width: '20%' }}>
            {inspectWheel()}
          </div>
          <div className="inspect-trim ml-1" style={{ width: '30%' }}>
            {inspectTrim()}
          </div>

          {showButtonSubmitVehicle ? (
            <div className="submit d-block ml-2 " style={{ width: '5%' }}>
              <div
                className="btn btn-success d-block "
                onClick={() =>
                  props.vehicleData(inspection.sessionID, dataUpdateVehicle)
                }
              >
                Submit
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
      {listSteps &&
        listSteps.length > 0 &&
        listSteps.map((step, index) => {
          const photoCode = step?.photoCode ?? 'All';
          let taskRejected = step?.tasks?.filter(
            (task) => task.status === 'REJECTED',
          );
          taskRejected = _orderBy(taskRejected, ['createdAt'], ['desc']);
          const taskAccepted = step?.tasks?.filter(
            (task) => task.status !== 'REJECTED',
          );

          return step?.tasks?.length > 0 ? (
            <div
              className="photoCode mt-3"
              key={`photo_code_step` + index}
              style={{
                padding: '10px 15px',
                border: '1px solid #675d7a40',
                borderRadius: '25px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                }}
              >
                <div style={{ width: '2%' }}>
                  <div
                    className="number"
                    style={{
                      display: 'inline-block',
                      padding: '3px 6px',
                      background: '#675d7a',
                      fontWeight: 'bold',
                      fontSize: '16px',
                      color: '#fff',
                      borderRadius: '5px',
                    }}
                  >
                    {photoCode}
                  </div>
                </div>
                {taskAccepted.length > 0
                  ? taskAccepted.map((item, indexAc) => {
                      return (
                        <div
                          key={indexAc + 'task-accepted'}
                          className="process_photocode ml-2"
                          style={{
                            display: 'inline-block',
                            width: '100%',
                          }}
                        >
                          <ProcessData
                            step={step}
                            task={item}
                            user={user}
                            inspection={inspection}
                            themeName={themeName}
                            showReport={showReport}
                            showReject={showReject}
                            showInspect={showInspect}
                            onAcceptTask={onAcceptTask}
                            showModalFinal={showModalFinal}
                            damage_table={damage_table}
                            qcPassed={qcPassed}
                          />
                        </div>
                      );
                    })
                  : null}
              </div>
              {taskRejected.length > 0 ? (
                <RejectData
                  taskRejected={taskRejected}
                  onAcceptTask={onAcceptTask}
                  statusInspection={statusInspection}
                  user={user}
                  inspection={inspection}
                  showReject={showReject}
                  showModalFinal={showModalFinal}
                />
              ) : null}
            </div>
          ) : null;
        })}
      <ModalMD
        show={showListReject}
        title="Reject Task"
        body={
          <div className="main-content">
            <form onSubmit={rejectTask}>
              <div className="form-group">
                <label className="text-bold">Status</label>
                <select className="custom-select small" name="status">
                  {itemReject}
                </select>
                <input
                  type="hidden"
                  value={taskId ? taskId : ''}
                  name="taskId"
                />
              </div>
              <button className="btn btn-warning">
                <span className="text-bold">OK</span>
              </button>
            </form>
          </div>
        }
        sizeModal="modal-md"
        handleClose={() => setshowListReject(false)}
      />
      <ModalMD
        show={showlistReport}
        title="Reject Task"
        body={
          <div className="main-content">
            <form onSubmit={reportImage}>
              <div className="form-group">
                <label className="text-bold">Status</label>
                <select className="custom-select small" name="reason">
                  {itemReport}
                </select>
                <input
                  type="hidden"
                  value={taskId ? taskId : ''}
                  name="taskId"
                />
              </div>
              <button className="btn btn-warning">
                <span className="text-bold">OK</span>
              </button>
            </form>
          </div>
        }
        sizeModal="modal-md"
        handleClose={() => setshowlistReport(false)}
      />
      {showModalInspect && (
        <ModalInspect
          taskId={taskId}
          hideModal={() => setshowModalInspect(false)}
        />
      )}
      {showModalImage && (
        <ModalFinal
          user={user}
          tasks={tasks}
          vehicle={inspection.vehicle || {}}
          task={dataTask && dataTask.task ? dataTask.task : null}
          inspection={inspection}
          src={dataTask && dataTask.url ? dataTask.url : ''}
          agent={dataTask && dataTask.agent ? dataTask.agent : false}
          notGen={dataTask && dataTask.notGen ? dataTask.notGen : false}
          final={dataTask && dataTask.final ? dataTask.final : false}
          hideModal={() => hideModalFinal()}
          dataValidate={dataValidate}
          onChangeValidatePhoto={onChangeValidatePhoto}
          confirmValidatePhoto={confirmValidatePhoto}
        />
      )}
    </div>
  );
};

export default DetailTask;
