export const caculatePostionShow = (offsetWidth, offsetHeight, item) => {
  console.log("🚀 ~ caculatePostionShow ~ item:", item)
  // Tru 10 vi duong kinh cua point (icon css )
  // đường kính của icon là 21 px nên - 10 px để ra chính giữa
  const coodrsX = (item?.coodrs?.x || item?.coords?.x) ?? 0;
  const coodrsY = (item?.coodrs?.y || item?.coords?.y) ?? 0;
  const newLocaX = Number(coodrsX * offsetWidth).toFixed(4) - 10;
  const newLocaY = Number(coodrsY * offsetHeight).toFixed(4) - 10;
  return {
    x: newLocaX,
    y: newLocaY,
  };
};
export const checkDamageNotAction = (listDamage, classDamage, classRnd) => {
  for (let i = 0; i < listDamage.length; i++) {
    const damage = listDamage[i];
    const damageName = damage && damage.damage_name ? damage.damage_name : false;
    if (!damageName) {
      const pointRnd = document.getElementById(`${classDamage}-${i}`);
      const point = document.getElementById(`${classRnd}-${i}`);
      if (pointRnd && point) {
        pointRnd.classList.add('heartBeat');
        point.classList.add('heartBeat');
        setTimeout(() => {
          pointRnd.classList.remove('heartBeat');
          point.classList.remove('heartBeat');
        }, 1000);
      }
      return true;
    }
  }
  return false;
};
