import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { Rnd } from 'react-rnd';
import Hotkeys from 'react-hot-keys';
import PrismaZoom from 'react-prismazoom';
import { caculatePostionShow } from '../../../../../utils/position';
import { genPhoto } from '../../../../../utils/photos';
window.$ = $;

const ModalFinal = (props) => {
  const {
    src,
    bgColor,
    task,
    hideModal,
    agent,
    final,
    notGen,
    vehicle,
    tasks,
    inspection,
    dataValidate,
    onChangeValidatePhoto,
    confirmValidatePhoto,
    user,
  } = props;
  // DATA FOR HITL VALIDATE PHOTO
  const catalog = inspection?.catalog ?? null;
  const avnCatalog = catalog?.avn?.toString() ?? '';
  const vlpCatalog = catalog?.license_plate_number ?? '';
  const avn = task?.dataHitl?.avn ?? '';
  const vlp = task?.dataHitl?.vlp ?? '';
  const invalidDone = task?.dataHitl?.done ?? false;
  const invalid = task?.dataHitl?.invalid ?? false;
  const aiData = task?.aiData ?? null;
  const skillsUser = user?.skills ?? [];
  const optionsAVN = [
    {
      label: 'Wrong Vehicle',
      value: 1,
    },
    {
      label: 'No Avn',
      value: 2,
    },
    {
      label: 'Hallucination',
      value: 3,
    },
  ];
  const optionsVLP = [
    {
      label: 'Wrong Vehicle',
      value: 1,
    },
  ];
  ///
  const inspector = inspection?.inspector;
  const group = inspection?.group ?? 'DEFAULT';
  const cageInfo = task?.options?.cageInfo ?? null;

  let supportView = task?.options?.supportView ?? [];

  const photoCodeSupport = task?.photoCodeSupport ?? [];
  if (photoCodeSupport) {
    let taskSupport = [];
    for (let i = 0; i < photoCodeSupport.length; i++) {
      const photoCode = photoCodeSupport[i];
      const support = tasks.filter(
        (task) =>
          task.photoCode === photoCode &&
          ['ACCEPTED', 'FINISHED', 'INSPECTING'].includes(task.status),
      );
      if (support && support.length > 0) {
        const linkUrlPhoto = genPhoto(support[0]);
        taskSupport.push(linkUrlPhoto);
      }
    }
    supportView = taskSupport;

    let photoSearch01, photoSearch02;

    switch (task.photoCode) {
      case '04':
        photoSearch01 = 'capture/10';
        photoSearch02 = 'capture/13';
        break;
      case '05':
        photoSearch01 = 'capture/10';
        photoSearch02 = 'capture/11';

        break;
      case '07':
        photoSearch01 = 'capture/11';
        photoSearch02 = 'capture/12';
        break;
      case '08':
        photoSearch01 = 'capture/12';
        photoSearch02 = 'capture/13';
        break;
      default:
        break;
    }
    if (['04', '05', '07', '08'].includes(task.photoCode)) {
      supportView = [
        ...supportView.filter((item) => item.includes(photoSearch01)),
        ...supportView.filter((item) => item.includes(photoSearch02)),
        ...supportView.filter(
          (item) =>
            !item.includes(photoSearch01) && !item.includes(photoSearch02),
        ),
      ];
    }
  }
  let combinedData;
  let inspectData =
    task.status === 'FINISHED' ? task.inspectData : combinedData;
  let damageNotGen =
    inspectData && inspectData.detected_damages
      ? inspectData.detected_damages
      : [];

  let damageAgent =
    inspectData && inspectData.detected_damages
      ? inspectData.detected_damages
      : [];
  let damageInterior = inspectData?.interior_cleanliness ?? [];
  let damageFinal = damageAgent
    .filter((item) => {
      return item.admin_response !== 'reject';
    })
    .filter((item) => {
      return item.verifyDamage !== 'remove';
    });
  const urlCage = cageInfo && cageInfo.cageSvg ? cageInfo.cageSvg : null;
  let modalBodyStyle = { backgroundColor: bgColor || '#EEE' };
  const [showCage, setshowCage] = useState(false);
  const [showSupport, setshowSupport] = useState(false);
  const [srcSup, setsrcSup] = useState('');
  const [cage, setcage] = useState({
    cage: {
      width: 0,
      height: 0,
      x: 0,
      y: 0,
    },
  });
  const [loadImgDone, setloadImgDone] = useState(false);
  const [rotate, setrotate] = useState(0);
  const [damagePoint, setdamagePoint] = useState([]);
  const [sizeModal, setsizeModal] = useState(true);
  const [size, setsize] = useState('modal-xl');
  const [zoom, setzoom] = useState(1);
  const [showAIDamage, setshowAIDamage] = useState(false);
  const [showDamages, setshowDamages] = useState(true);
  const [listSize] = useState(['modal-xl', 'modal-full']);
  let [listSizeLabel] = useState({
    'modal-xl': 'XL',
    'modal-full': 'FL',
  });
  let showAi = false;
  if (
    skillsUser.includes('AI') ||
    ['TRAINER', 'SUPER', 'ADMIN'].includes(user.role)
  ) {
    showAi = true;
  }
  const damage_count = aiData?.damage_count?.total ?? 0;

  const uiDamageAI = () => {
    const damages = aiData?.damages ?? [];
    let offsetWidth = $('.App-zoom').width();
    let offsetHeight = $('.App-zoom').height();
    return showAi && aiData
      ? damages.map((damage, index) => {
          let boxDamagePoint = {
            position: 'absolute',
            zIndex: 88,
            color: 'yellow',
          };
          if (damage.keypoint) {
            const xMin = damage?.keypoint[0] * offsetWidth;
            const yMin = damage?.keypoint[1] * offsetHeight;
            boxDamagePoint.left = `${xMin - 11}px`;
            boxDamagePoint.top = `${yMin - 8}px`;
          }
          const label = damage['damage-label'] ?? null;
          return (
            <div key={`AIDamage-${index}`} style={boxDamagePoint}>
              {label ? (
                <span
                  style={{
                    position: 'absolute',
                    top: '-22px',
                    left: '-15px',
                    margin: 0,
                  }}
                >
                  {label}
                </span>
              ) : null}
              {damage.keypoint ? (
                <i
                  style={{ margin: 0 }}
                  className="far fa-lg fa-dot-circle"
                ></i>
              ) : null}
            </div>
          );
        })
      : null;
  };
  useEffect(() => {
    if (
      [
        '02',
        '04',
        '05',
        '07',
        '08',
        '06',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
      ].includes(task.photoCode)
    ) {
      loadImage();
    }
    // eslint-disable-next-line
  }, []);
  let timeoutId;
  useEffect(() => {
    if (loadImgDone && notGen) {
      timeoutId = setTimeout(() => {
        loadDamages();
      }, 500);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [loadImgDone, size]);
  const loadDamages = () => {
    let offsetWidth = $(`#img-annotated`).width();
    let offsetHeight = $(`#img-annotated`).height();
    const arrayPoint = [];
    if (task && task.photoCode === '02') {
      damageNotGen = damageInterior;
    }
    if (damageNotGen && damageNotGen.length > 0) {
      for (let i = 0; i < damageNotGen.length; i++) {
        const item = damageNotGen[i];
        const username = item?.username ?? null;
        const admin_response = item?.admin_response ?? null;
        const user_response = item?.user_response ?? null;
        let grade_score = item?.grade_score ?? null;
        if (task.photoCode === '02') {
          grade_score = item?.issue_score ?? 0;
        }
        const status = item?.status;
        let strokeColor;
        if (grade_score === 0) {
          strokeColor = 'grey';
        } else if (grade_score === 1) {
          strokeColor = 'yellow';
        } else if (grade_score === 2) {
          strokeColor = 'orange';
        } else if (grade_score >= 3) {
          strokeColor = 'red';
        }
        const source = item?.source ?? null;
        const { x, y } = caculatePostionShow(offsetWidth, offsetHeight, item);
        arrayPoint.push({
          x: x,
          y: y,
          username: username,
          status: status,
          admin_response: admin_response,
          user_response: user_response,
          source: source,
          strokeColor: strokeColor,
        });
      }
    }
    setdamagePoint(arrayPoint);
  };
  const loadImage = () => {
    let img = (task && task.image_cap) || false;
    const imgLoad = new Image();
    imgLoad.src = img;
    imgLoad.onload = () => {
      setloadImgDone(true);
    };
  };
  const handleShowCage = () => {
    const offsetWidth = $(`#img-annotated`).width();
    const offsetHeight = $(`#img-annotated`).height();
    const cageInfo =
      task && task.options && task.options.cageInfo
        ? task.options.cageInfo
        : null;
    const actualImageWidth =
      cageInfo && cageInfo.actual_image && cageInfo.actual_image.width
        ? cageInfo.actual_image.width
        : 0;
    const actualImageHeight =
      cageInfo && cageInfo.actual_image && cageInfo.actual_image.height
        ? cageInfo.actual_image.height
        : 0;
    const ratioX = offsetWidth / actualImageWidth;
    const ratioY = offsetHeight / actualImageHeight;
    let wCage =
      cageInfo && cageInfo.cage && cageInfo.cage.width
        ? Math.round(cageInfo.cage.width) * ratioX
        : 0;
    let hCage =
      cageInfo && cageInfo.cage && cageInfo.cage.height
        ? Math.round(cageInfo.cage.height) * ratioY
        : 0;

    let xCage =
      cageInfo && cageInfo.cage && cageInfo.cage.x
        ? Math.round(cageInfo.cage.x) * ratioX
        : 0;
    let yCage =
      cageInfo && cageInfo.cage && cageInfo.cage.y
        ? Math.round(cageInfo.cage.y) * ratioY
        : 0;
    setshowCage(!showCage);
    setcage({
      width: Math.round(wCage),
      height: Math.round(hCage),
      x: Math.round(xCage),
      y: Math.round(yCage),
    });
  };
  const onImgSup = (srcSup) => {
    setshowSupport(true);
    setsrcSup(srcSup);
  };
  const offImgSup = () => {
    setshowSupport(false);
  };
  const onChangeSize = (newSize) => {
    if (size !== newSize && listSize.includes(newSize)) {
      setsize(newSize);
    }
  };
  const changeSize = () => {
    setsizeModal(!sizeModal);

    if (sizeModal) {
      setsize('modal-xl');
    } else {
      setsize('modal-full');
    }
  };
  return (
    <div className="modal file-modal show-modal" style={{ overflow: 'auto' }}>
      <div
        className="overlay"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          zIndex: '98',
        }}
        onClick={hideModal}
      ></div>
      <div className={`modal-dialog ${size}`} role="document">
        <div
          className="modal-content"
          style={{
            zIndex: '99',
            border:
              (avn || vlp) && invalid
                ? '2px solid red'
                : (avn || vlp) && invalid
                ? '2px solid green'
                : '',
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title d-flex align-items-center">
              <span className="d-inline-block mr-2">{task.photoCode}</span>
              {avn || vlp ? (
                <div>
                  <span
                    className={`d-inline-block badge badge-${
                      invalid ? 'danger' : 'success'
                    } text-head mr-1`}
                  >
                    {invalid ? 'INVALID' : 'VALID'}
                  </span>
                  {invalidDone ? (
                    <span className="badge badge-success text-head">
                      <i className="fas fa-check-circle"></i>
                    </span>
                  ) : null}
                </div>
              ) : null}
            </h5>
            <Hotkeys
              keyName="w"
              onKeyUp={() => setzoom(1)}
              onKeyDown={() => setzoom(4)}
            />
            <Hotkeys
              keyName="d"
              onKeyDown={() => setshowDamages(!showDamages)}
            />
            <Hotkeys keyName="esc" onKeyDown={() => hideModal()}>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={hideModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </Hotkeys>
          </div>
          <div className="modal-body" style={modalBodyStyle}>
            <div className="btn-group mb-1">
              {listSize.map((sz) => {
                return (
                  <Hotkeys
                    keyName="T"
                    onKeyDown={() => changeSize()}
                    key={`Size_button_${sz}`}
                  >
                    <button
                      className={`btn btn-tiny mr-1 ${
                        size === sz ? 'active btn-warning' : 'btn-light'
                      }`}
                      style={{
                        fontSize: '13px',
                        borderRadius: '5px',
                      }}
                      onClick={() => onChangeSize(sz)}
                    >
                      {listSizeLabel[`${sz}`]}
                    </button>
                  </Hotkeys>
                );
              })}
              {task.photoCode === '01' ? (
                <button
                  className="btn btn-tiny ml-2"
                  onClick={() => setrotate(rotate + 90)}
                >
                  <i className="fas fa-sync"></i>
                </button>
              ) : null}
            </div>
            <div className="row mb-2">
              <div className="col-12 mb-1">
                <div
                  className="support-view"
                  style={{
                    position: 'relative',
                    zIndex: '2',
                  }}
                >
                  <div
                    className="title mr-2"
                    style={{
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                      display: 'inline-block',
                      fontSize: '13px',
                    }}
                  >
                    Support Img
                  </div>
                  {supportView.length > 0 &&
                    supportView.map((item, index) => {
                      return (
                        <Hotkeys
                          key={`Image_` + index}
                          keyName={`${index + 1}`}
                          onKeyDown={() => onImgSup(item, index)}
                          onKeyUp={() => offImgSup()}
                        >
                          <div
                            className="img mr-2"
                            onClick={() => onImgSup(item, index)}
                            style={{
                              display: 'inline-block',
                              width: '35px',
                              height: '35px',
                              borderRadius: '15px',
                              cursor: 'pointer',
                            }}
                          >
                            <img
                              style={{
                                width: '100%',
                                borderRadius: '15px',
                              }}
                              src={`${item}`}
                              alt="img-sup"
                            ></img>
                          </div>
                        </Hotkeys>
                      );
                    })}
                  {supportView.length > 0 && (
                    <Hotkeys
                      keyName={`${supportView.length + 1}`}
                      onKeyDown={() => onImgSup(task.image_cap)}
                      onKeyUp={() => offImgSup()}
                    >
                      <div
                        className="img"
                        onClick={() => onImgSup(task.image_cap)}
                        style={{
                          display: 'inline-block',
                          borderRadius: '15px',
                          fontSize: '12px',
                          cursor: 'pointer',
                          padding: '2px 5px',
                          border: '1px solid #000',
                          textTransform: 'uppercase',
                          fontWeight: 'bold',
                        }}
                      >
                        Origi
                      </div>
                    </Hotkeys>
                  )}
                  {showAi ? (
                    <div className="ml-2 d-inline-block">
                      <div className="custom-control custom-switch d-inline mr-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="AIDamage"
                          checked={showAIDamage}
                          onChange={() => setshowAIDamage(!showAIDamage)}
                        />
                        <label
                          className="custom-control-label text-head mb-1"
                          htmlFor="AIDamage"
                        >
                          AI Damages
                        </label>
                      </div>
                      <div
                        className="badge badge-primary text-head d-inline"
                        style={{
                          fontSize: '15px',
                        }}
                      >
                        <i className="fas fa-laptop-code mr-1"></i>
                        {damage_count} damages
                      </div>
                    </div>
                  ) : null}
                  {inspectData && inspectData.vin && task.photoCode === '01' ? (
                    <div
                      className="info ml-2"
                      style={{
                        display: 'inline-block',
                        //justifyContent: 'center',
                      }}
                    >
                      <div
                        className="group-info"
                        style={{
                          background: '#fff',
                          padding: '5px 25px',
                          fontSize: '15px',
                          color: 'red',
                          borderRadius: '15px',
                        }}
                      >
                        <span>
                          {inspectData && inspectData.vin
                            ? inspectData.vin
                            : ''}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              {showSupport ? (
                <div
                  className="img-sup"
                  style={{
                    position: 'absolute',
                    left: '5%',
                    top: '15px',
                    width: '90%',
                    borderRadius: '15px',
                    zIndex: 999,
                  }}
                >
                  <img
                    src={`${srcSup}`}
                    alt="img-support"
                    style={{
                      width: '100%',
                      borderRadius: '15px',
                    }}
                  ></img>
                  <Hotkeys keyName="x" onKeyDown={() => offImgSup()}>
                    <span
                      style={{
                        position: 'absolute',
                        right: '20px',
                        top: '20px',
                        color: '#fff',
                        padding: '7px 15px',
                        borderRadius: '50%',
                        background: '#000',
                        fontSize: '17px',
                        cursor: 'pointer',
                      }}
                      onClick={() => offImgSup()}
                    >
                      &times;
                    </span>
                  </Hotkeys>
                </div>
              ) : null}

              <div className={`${avn || vlp ? 'col-9' : 'col-12'}`}>
                {urlCage ? (
                  <button
                    className={`btn btn-tiny btn-show-cage ${
                      showCage ? 'active btn-warning' : 'btn-light'
                    }`}
                    style={{ zIndex: '999' }}
                    onClick={() => handleShowCage()}
                  >
                    {showCage ? 'Hide' : 'Show'} Agent Fixed Cage
                  </button>
                ) : null}

                <div
                  className="img-annotated"
                  id="img-annotated"
                  style={{
                    overflow: 'hidden',
                  }}
                >
                  <PrismaZoom
                    className="App-zoom"
                    minZoom={1}
                    maxZoom={zoom}
                    leftBoundary={0}
                    topBoundary={0}
                  >
                    <img
                      src={src}
                      width="100%"
                      height="auto"
                      alt="Agent"
                      style={{
                        transform: `rotate(${rotate}deg)`,
                        width: '100%',
                        height: `${
                          task.photoCode === '01' ? 'calc(90vh)' : '100%'
                        }`,
                      }}
                      className="img-lazy-load"
                    ></img>
                    {showAIDamage ? uiDamageAI() : null}
                  </PrismaZoom>

                  {showDamages &&
                    damagePoint &&
                    damagePoint.length > 0 &&
                    damagePoint.map((item, index) => {
                      return (
                        <Rnd
                          className="damage-point full"
                          key={`damage-inspected-${index}`}
                          style={{
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: '0 0',
                            backgroundSize: 'contain',
                            zIndex: 999,
                          }}
                          position={{
                            x: item.x,
                            y: item.y,
                          }}
                          lockAspectRatio={true}
                          enableResizing={false}
                          disableDragging={true}
                        >
                          <div className="admin-point">{index + 1}</div>
                          {item.source === 'user' ||
                          (group === 'AMZ' &&
                            item.username === inspector &&
                            item.admin_response !== 'reject' &&
                            item.status !== 'repaired') ? (
                            <i
                              className="far fa-square"
                              style={{
                                color: item.strokeColor,
                              }}
                            ></i>
                          ) : item.admin_response === 'reject' ||
                            item.user_response === 'reject' ||
                            item.status === 'repaired' ? (
                            <i
                              className={
                                item.username === inspector
                                  ? 'fas fa-square'
                                  : `fas fa-exclamation-triangle`
                              }
                              style={{
                                color: item.strokeColor,
                              }}
                            ></i>
                          ) : item.admin_response === 'add' ? (
                            <i
                              className="far fa-circle"
                              style={{
                                color: item.strokeColor,
                              }}
                            ></i>
                          ) : item.admin_response === 'edit' ? (
                            <i
                              className="far fa-dot-circle"
                              style={{
                                color: item.strokeColor,
                              }}
                            ></i>
                          ) : (
                            <i
                              className="far fa-square"
                              style={{
                                color: item.strokeColor,
                              }}
                            ></i>
                          )}
                        </Rnd>
                      );
                    })}
                </div>
                {urlCage && showCage ? (
                  <Rnd
                    className="rnd-cage "
                    style={{
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: '0 0',
                      backgroundSize: 'contain',
                    }}
                    size={{
                      width: cage.width,
                      height: cage.height,
                    }}
                    position={{ x: cage.x, y: cage.y }}
                    lockAspectRatio={true}
                    disableDragging={true}
                  >
                    <img src={urlCage} alt="Cage" />
                  </Rnd>
                ) : null}
                {vehicle ? (
                  <div
                    className="info mt-2 mb-2"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      className="group-info"
                      style={{
                        background: '#fff',
                        padding: '5px 25px',
                        fontSize: '17px',
                        color: 'red',
                        borderRadius: '15px',
                      }}
                    >
                      <span>
                        {vehicle && vehicle.year ? vehicle.year : ''}-
                        {vehicle && vehicle.make ? vehicle.make : ''}-
                        {vehicle && vehicle.model ? vehicle.model : ''}-
                        {vehicle && vehicle.bodyType ? vehicle.bodyType : ''}
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
              {avn || vlp ? (
                <div className="col-3">
                  {catalog ? (
                    <div>
                      <div
                        style={{
                          fontSize: '22px',
                        }}
                        className=" text-head text-uppercase mb-1"
                      >
                        <i className="fas fa-clipboard-list mr-1"></i>Catalog
                      </div>
                      {avnCatalog ? (
                        <div className="number d-flex mb-1">
                          <div className="badge badge-dark text-head mr-2 mb-1">
                            AVN Catalog
                          </div>
                          <div className=" text-head">{avnCatalog}</div>
                        </div>
                      ) : null}
                      {vlpCatalog ? (
                        <div className="number d-flex mb-1">
                          <div className="badge badge-dark text-head mr-2 mb-1">
                            VLP Catalog
                          </div>
                          <div className=" text-head">{vlpCatalog}</div>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  <div
                    style={{
                      fontSize: '22px',
                    }}
                    className=" text-head text-uppercase mb-1"
                  >
                    <i className="fas fa-clipboard-list mr-1"></i>Validate Photo
                  </div>
                  <div className="data-validate">
                    {avn ? (
                      <div className="avn">
                        <div className="number d-flex mb-1">
                          <div className="badge badge-dark text-head mr-2 mb-1">
                            AI AVN
                          </div>
                          <div className=" text-head">{avn}</div>
                        </div>
                        <div className="input mb-1">
                          <input
                            style={{
                              textTransform: 'uppercase',
                              transition: 'all .3s ease',
                            }}
                            placeholder="AVN"
                            type="text"
                            className="form-control text-head"
                            id="avn"
                            name="avn"
                            value={dataValidate?.avn ?? ''}
                            onChange={(e) => onChangeValidatePhoto(e)}
                          />
                        </div>
                        <div className="options">
                          {optionsAVN.map((item, index) => {
                            const value = item.value;
                            const select =
                              item.value === dataValidate.avn_feedback
                                ? true
                                : false;
                            const e = {
                              target: {
                                name: 'avn_feedback',
                                value: value,
                              },
                            };
                            return (
                              <div
                                key={`${index}-avn`}
                                className="item-Avn mb-1"
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={() => onChangeValidatePhoto(e)}
                              >
                                <div className="badge badge-pill badge-dark text-head">
                                  <span
                                    className="mr-2"
                                    style={{
                                      fontSize: '16px',
                                    }}
                                  >
                                    {select ? (
                                      <i className="text-success fas fa-check-circle"></i>
                                    ) : (
                                      <i className="far fa-square"></i>
                                    )}
                                  </span>

                                  <span style={{ fontSize: '16px' }}>
                                    {item.label}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                    {vlp ? (
                      <div className="vlp">
                        <div className="number d-flex">
                          <div className="badge badge-dark text-head mr-2 mb-1">
                            AI PLATE
                          </div>
                          <div className=" text-head">{vlp}</div>
                        </div>
                        <div className="input mb-1">
                          <input
                            style={{
                              textTransform: 'uppercase',
                              transition: 'all .3s ease',
                            }}
                            placeholder="PLATE"
                            type="text"
                            className="form-control text-head"
                            id="vlp"
                            name="vlp"
                            value={dataValidate?.vlp ?? ''}
                            onChange={(e) => onChangeValidatePhoto(e)}
                          />
                        </div>
                        <div className="options">
                          {optionsVLP.map((item, index) => {
                            const value = item.value;
                            const select =
                              item.value === dataValidate.vlp_feedback
                                ? true
                                : false;
                            const e = {
                              target: {
                                name: 'vlp_feedback',
                                value: value,
                              },
                            };
                            return (
                              <div
                                key={`${index}-avn`}
                                className="item-Avn mb-1"
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={() => onChangeValidatePhoto(e)}
                              >
                                <div className="badge badge-pill badge-dark text-head">
                                  <span
                                    className="mr-2"
                                    style={{
                                      fontSize: '16px',
                                    }}
                                  >
                                    {select ? (
                                      <i className="text-success fas fa-check-circle"></i>
                                    ) : (
                                      <i className="far fa-square"></i>
                                    )}
                                  </span>
                                  <span style={{ fontSize: '16px' }}>
                                    {item.label}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {inspection.status === 'VALIDATING' ? (
                    <div
                      style={{
                        cursor: 'pointer',
                        fontSize: '17px',
                      }}
                      onClick={() => confirmValidatePhoto()}
                      className="badge badge-success text-head d-block badge-pill"
                    >
                      Confirm
                    </div>
                  ) : null}
                </div>
              ) : null}
              {agent && damageAgent.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    className="list mt-2"
                    style={{
                      width: '95%',
                      padding: '15px 25px',
                      borderRadius: '15px',
                      border: '1px solid #321640',
                    }}
                  >
                    {damageAgent.map((item, index) => {
                      const disable =
                        item.user_response === 'reject' ||
                        item.admin_response === 'reject'
                          ? true
                          : null;
                      return (
                        <div
                          className={`list-damage text-head row mb-1 ${
                            disable ? 'text-logan text-strike' : ''
                          } ${
                            item.status === 'repaired'
                              ? 'text-logan text-strike'
                              : ''
                          }`}
                          key={`list_` + index}
                        >
                          <div className="col-6 text-sm">
                            <b>
                              <span className="number mr-1">({index + 1})</span>
                              ({item.damage_group}){item.component}
                            </b>
                          </div>
                          <div className="col-6 text-sm">
                            {item.damage_name}

                            {item.material ? (
                              <div
                                style={{
                                  color: '#fff',
                                }}
                                className="badge badge-primary text-head text-uppercase ml-1"
                              >
                                {item.material === 'Painted' ? (
                                  <i className="fas fa-fill-drip"></i>
                                ) : item.material === 'Textured Surface' ? (
                                  <i className="fas fa-recycle"></i>
                                ) : item.material === 'Chrome' ? (
                                  <i className="far fa-gem"></i>
                                ) : item.material === 'Alloy Wheel' ? (
                                  <i className="fas fa-drum-steelpan"></i>
                                ) : item.material === 'Plastic Cover' ? (
                                  <i className="fas fa-record-vinyl"></i>
                                ) : item.material === 'Steel Wheel' ? (
                                  <i className="fas fa-dumbbell"></i>
                                ) : item.material === 'Glass' ? (
                                  <i className="fas fa-glass-whiskey"></i>
                                ) : item.material === 'Steel' ? (
                                  <i className="fas fa-dharmachakra"></i>
                                ) : null}
                              </div>
                            ) : null}
                            {item.status ? (
                              <div
                                className={` ml-1 badge ${
                                  item.status === 'repaired'
                                    ? 'badge-warning'
                                    : 'badge-primary'
                                }`}
                              >
                                {item.status}
                              </div>
                            ) : null}
                            {item.source === 'user' ||
                            item.user_response === 'reject' ? (
                              <div className="badge badge-primary float-right">
                                <span className="text-light text-uppercase">
                                  User
                                </span>
                              </div>
                            ) : item.username ? (
                              <div
                                className={`badge ${
                                  item.admin_response === 'reject'
                                    ? 'badge-danger'
                                    : 'badge-primary'
                                } float-right`}
                              >
                                <span className="text-light text-uppercase">
                                  {item.username}
                                </span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {final && damageFinal.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    className="list mt-2"
                    style={{
                      width: '95%',
                      padding: '15px 25px',
                      borderRadius: '15px',
                      border: '1px solid #321640',
                    }}
                  >
                    {damageFinal.map((item, index) => {
                      return (
                        <div
                          className={`list-damage row mb-1 text-head ${
                            item.status === 'repaired'
                              ? 'text-logan text-strike'
                              : ''
                          }`}
                          key={`list_` + index}
                        >
                          <div className="col-6 text-sm">
                            <span className="number mr-1">
                              ({item.damage_id ? item.damage_id : index + 1})
                            </span>
                            ({item.damage_group}){item.component}
                          </div>
                          <div className="col-6 text-sm">
                            {item.damage_name}
                            {item.material ? (
                              <div
                                style={{
                                  color: '#fff',
                                }}
                                className="badge badge-primary text-head text-uppercase ml-2"
                              >
                                {item.material === 'Painted' ? (
                                  <i className="fas fa-fill-drip"></i>
                                ) : item.material === 'Textured Surface' ? (
                                  <i className="fas fa-recycle"></i>
                                ) : item.material === 'Chrome' ? (
                                  <i className="far fa-gem"></i>
                                ) : item.material === 'Alloy Wheel' ? (
                                  <i className="fas fa-drum-steelpan"></i>
                                ) : item.material === 'Plastic Cover' ? (
                                  <i className="fas fa-record-vinyl"></i>
                                ) : item.material === 'Steel Wheel' ? (
                                  <i className="fas fa-dumbbell"></i>
                                ) : item.material === 'Glass' ? (
                                  <i className="fas fa-glass-whiskey"></i>
                                ) : item.material === 'Steel' ? (
                                  <i className="fas fa-dharmachakra"></i>
                                ) : null}
                              </div>
                            ) : null}
                            {item.status ? (
                              <div
                                className={` ml-1 badge ${
                                  item.status === 'repaired'
                                    ? 'badge-warning'
                                    : 'badge-primary'
                                }`}
                              >
                                {item.status}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModalFinal;
