import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PrismaZoom from 'react-prismazoom';
import { Link } from 'react-router-dom';
import InfoBar from '../../inspect/containers/components/InfoBar';
import ModalImage from '../../inspect/containers/components/ModalImage';
import TitleInspect from '../../inspect/containers/components/TitleInspect';
import Hotkeys from 'react-hot-keys';
import { Rnd } from 'react-rnd';
import moment from 'moment-timezone';
import {
  deleteTaskFailed,
  getTaskFailed,
  confirmTaskFailed,
} from '../operations';
import ModalImageFull from '../../inspect/containers/components/ModalImageFull';
import ModalConfirm from './components/ModalConfirm';
import ModalShowErr from './components/ModalShowErr';
import InspectorData from './components/InspectorData';
import QaData from './components/QaData';
import Notes from './components/Notes';
import Action from './components/Action';
import { caculatePostionShow } from '../../../utils/position';
import { genPhoto } from '../../../utils/photos';
const TaskFailedView = (props) => {
  const [support, setsupport] = useState({ support: '', index: 0 });
  const [showmodalConfirm, setshowmodalConfirm] = useState(false);
  const [showwarning, setshowwarning] = useState(false);
  const [messwarning, setmesswarning] = useState('');
  const [modalImage, setmodalImage] = useState(false);
  const [modalImageFull, setmodalImageFull] = useState(false);
  const [damagePoint, setdamagePoint] = useState([]);
  const [message, setmessage] = useState('');
  const [rotate, setrotate] = useState(0);
  const [firstTime, setfirstTime] = useState(false);
  const [showDamagesPoint, setshowDamagesPoint] = useState(true);
  const [qaData, setqaData] = useState(false);
  const { id } = props.match.params;
  const { item } = props.taskFailed;
  const { auth } = props;
  const user = auth?.user ?? '';
  const taskFailed = item?.taskFailed ?? null;
  const idTaskFailed = taskFailed?._id ?? '';
  const status = taskFailed?.status ?? false;
  const notes = taskFailed?.notes ?? [];
  const taskInspect = item?.task ?? {};
  const inspectionInspect = item?.inspection ?? {};
  let taskSetting = item?.taskSetting ?? false;
  const vehicle = item?.vehicle ?? null;
  const vehData = vehicle?.data ?? {};
  let imgSupport = taskInspect?.options?.supportView ?? [];
  const inspector = taskFailed?.inspector?.username ?? '';
  const qa = taskFailed?.qa?.username ?? '';
  const timezone = user?.options?.timezone ?? 'Asia/Ho_Chi_Minh';
  const createdAt =
    taskFailed && taskFailed.createdAt
      ? moment(taskFailed.createdAt).tz(timezone).format('DD/MM/YY hh:mm:ss A')
      : null;
  const group = inspectionInspect?.group ?? 'DEFAULT';
  useEffect(() => {
    if (!firstTime) {
      props.getTaskFailed(id);
      setfirstTime(true);
    }
    if (idTaskFailed) {
      loadDamages();
    }
    return () => {
      clearData();
    };
  }, [id, idTaskFailed, status]);
  const newTab = (id) => {
    const link = window.location.origin;
    let url = link + `/inspectionView/${id}`;
    const win = window.open(url, '_blank');
    win.focus();
  };
  let onModalImage = (item, index) => {
    setsupport({ support: item, index: index });
    setmodalImage(true);
  };
  let offModalImage = () => {
    setsupport({ support: '', index: 0 });
    setmodalImage(false);
  };
  const onChange = (e) => {
    setmessage(e.target.value);
  };
  const clearData = () => {
    setdamagePoint([]);
    setmessage('');
    setqaData(false);
    setshowmodalConfirm(false);
    setshowwarning(false);
    setmesswarning('');
    setfirstTime(false);
    setsupport({ support: '', index: 0 });
    setrotate(0);
  };
  const backTab = () => {
    const link = window.location.origin;
    let url = link + `/performance/listTaskFailed`;
    window.location.replace(url);
  };
  const deleteTaskFailed = (id) => {
    const data = {
      id: id,
    };
    props.deleteTaskFailed(data).then((res) => {
      if (res) {
        backTab();
      }
    });
  };
  const confirmData = () => {
    let messageSent =
      ['SUPER', 'TRAINER'].includes(user.role) && !message
        ? 'Confirm'
        : message;
    const data = {
      id: idTaskFailed,
      data: { ...qaData },
      message: messageSent,
    };
    if (!qaData) {
      setshowmodalConfirm(false);
      setshowwarning(true);
      setmesswarning('Please do some thing');
      return;
    }
    for (let i = 0; i < Object.keys(qaData).length; i++) {
      const key = Object.keys(qaData)[i];
      const item = qaData[key];
      if (
        taskFailed.infor.type === 'QC' &&
        ['messageData'].includes(key) &&
        item.length === 0
      ) {
        setshowmodalConfirm(false);
        setshowwarning(true);
        setmesswarning('Please choose reason mistake');
        return;
      }
      for (let j = 0; j < item.length; j++) {
        const element = item[j];
        if (['inspectData', 'messageData'].includes(key) && !element.action) {
          setshowmodalConfirm(false);
          setshowwarning(true);
          setmesswarning('Please choose all mistake if you want to confirm');
          return;
        }
        if (
          key === 'detected_damages' &&
          element.admin_response_taskFailed &&
          !element.action
        ) {
          setshowmodalConfirm(false);
          setshowwarning(true);
          setmesswarning('Please choose all mistake if you to sucess');
          return;
        }
      }
    }
    props.confirmTaskFailed(data);
    setshowmodalConfirm(false);
  };

  const renderConfirmButton = () => {
    if (
      ['VERIFIED', 'QA-AUTO-VERIFIED', 'INSPECTOR-AUTO-VERIFIED'].includes(
        status,
      )
    ) {
      return;
    }
    if (!['SUPER', 'TRAINER'].includes(user.role)) {
      if (status === 'VERIFYING') {
        return;
      }
      if (status === 'QA-REVIEWING' && user.username !== qa) {
        return;
      }
      if (status === 'INSPECTOR-REVIEWING' && user.username !== inspector) {
        return;
      }
      if (user.username !== inspector && user.username !== qa) {
        return;
      }
    }
    return (
      <button
        type="button"
        className="btn btn-success"
        style={{ width: '100%' }}
        onClick={() => setshowmodalConfirm(true)}
      >
        <i className="fa fa-check mr-2 check-icon" aria-hidden="true" />
        Confirm
      </button>
    );
  };
  const renderMessBox = () => {
    if (status === 'VERIFIED') {
      return;
    }
    if (!['SUPER', 'TRAINER'].includes(user.role)) {
      if (status === 'VERIFYING') {
        return;
      }
      if (status === 'QA-REVIEWING' && user.username !== qa) {
        return;
      }
      if (status === 'INSPECTOR-REVIEWING' && user.username !== inspector) {
        return;
      }
      if (user.username !== inspector && user.username !== qa) {
        return;
      }
    }
    return (
      <div className="mb-1">
        <div
          className="d-flex"
          style={{
            width: '100%',
            height: '100px',
            color: '#fff',
            fontSize: '15px',
            borderRadius: '5px',
          }}
        >
          <textarea
            className="mess"
            placeholder="Type Message here"
            onChange={onChange}
            name="message"
            value={message}
            style={{
              background: '#321640',
              width: '100%',
              borderRadius: '10px',
              borderTopLeftRadius: '0',
              position: 'relative',
              padding: '5px 10px',
              color: '#fff',
              fontFamily: 'Noto Sans',
            }}
          ></textarea>
        </div>
      </div>
    );
  };
  const renderStatus = (status) => {
    let icon;
    let backGround = '';
    let color = '';
    switch (status) {
      case 'INSPECTOR-REVIEWING':
        backGround = '#f1c40f';
        icon = <i className="fas fa-pen"></i>;
        color = 'white';
        break;
      case 'QA-REVIEWING':
        backGround = '#f1c40f';
        icon = <i className="fas fa-pen"></i>;
        color = 'white';
        break;
      case 'VERIFIED':
        backGround = '#2ecc71';
        icon = <i className="fas fa-check"></i>;
        color = 'white';
        break;
      case 'VERIFYING':
        backGround = '#321640';
        icon = <i className="fas fa-question"></i>;
        color = 'white';
        break;
      default:
        backGround = '#95a5a6';
        icon = <i className="fas fa-question"></i>;
        color = 'white';
        break;
    }
    return (
      <div className="status ml-2" style={{ display: 'inline-block' }}>
        <div
          style={{
            background: backGround,
            color: color,
            display: 'flex',
            fontSize: '17px',
            padding: '8px 12px',
            fontWeight: 'bold',
          }}
          className="badge text-uppercase"
        >
          {icon}
          <div className="status ml-2">{status ? status : 'Unknown'}</div>
        </div>
      </div>
    );
  };
  const toolHotKey = () => {
    return (
      <div className="tool-cage" style={{ display: 'none' }}>
        <Hotkeys
          keyName="space"
          onKeyDown={() => setrotate(rotate + 90)}
        ></Hotkeys>
        <Hotkeys
          keyName="s"
          onKeyDown={() => setmodalImageFull(true)}
          onKeyUp={() => setmodalImageFull(false)}
        ></Hotkeys>
        <Hotkeys
          keyName="d"
          onKeyDown={() => setshowDamagesPoint(!showDamagesPoint)}
        ></Hotkeys>
        {imgSupport && imgSupport.length > 0
          ? imgSupport.map((item, index) => {
              const linkUrlPhoto = genPhoto(item);
              return (
                <Hotkeys
                  key={`Image_` + index}
                  keyName={`${index + 1}`}
                  onKeyDown={() => onModalImage(linkUrlPhoto, index + 1)}
                  onKeyUp={() => offModalImage()}
                ></Hotkeys>
              );
            })
          : null}
      </div>
    );
  };
  const loadDamages = () => {
    let img = taskInspect && taskInspect.image_cap;
    const damage =
      taskFailed && taskFailed.qaData && taskFailed.qaData.detected_damages
        ? taskFailed.qaData.detected_damages
        : [];
    let data = taskFailed && taskFailed.qaData ? taskFailed.qaData : false;
    if (
      taskFailed &&
      taskFailed.status === 'VERIFYING' &&
      ['SUPER', 'TRAINER'].includes(user.role) &&
      data
    ) {
      for (let i = 0; i < Object.keys(data).length; i++) {
        const key = Object.keys(data)[i];
        const item = data[key];
        if (item && item.length > 0) {
          for (let j = 0; j < item.length; j++) {
            if (!item[j].done) {
              item[j].critical = true;
            }
          }
        }
      }
    }
    setqaData(data);
    const imgLoad = new Image();
    imgLoad.src = img;
    let arrayPoint = [];

    imgLoad.onload = () => {
      let offsetWidth = $(`#img-annotated`).width();
      let offsetHeight = $(`#img-annotated`).height();
      if (damage && damage.length > 0) {
        for (let i = 0; i < damage.length; i++) {
          const item = damage[i];
          const username = item?.username ?? null;
          const admin_response_taskFailed =
            item?.admin_response_taskFailed ?? null;
          const user_response = item?.user_response ?? null;
          const grade_score = item?.grade_score ?? 0;
          const status = item?.status ?? '';
          let strokeColor;
          if (grade_score === 0) {
            strokeColor = 'grey';
          } else if (grade_score === 1) {
            strokeColor = 'yellow';
          } else if (grade_score === 2) {
            strokeColor = 'orange';
          } else if (grade_score >= 3) {
            strokeColor = 'red';
          }
          const source = item?.source ?? null;
          const { x, y } = caculatePostionShow(offsetWidth, offsetHeight, item);

          arrayPoint.push({
            x: x,
            y: y,
            username: username,
            admin_response_taskFailed: admin_response_taskFailed,
            user_response: user_response,
            source: source,
            strokeColor: strokeColor,
            status: status,
          });
        }
      }
      setdamagePoint(arrayPoint);
    };
  };
  return (
    <div className="priority container-fluid mt-2">
      <h2 className="subtitle">
        <small>
          <Link to="/performance/listTaskFailed" className="text-logan">
            <i className="fa fa-arrow-left" />
            <span>Priority Manager</span>
          </Link>
        </small>
      </h2>
      <div className="row mb-2">
        <div className="col-12 col-sm-8">
          <h2 className="subtitle">
            <small className="d-flex align-items-center">
              {renderStatus(status)}
              <div className="badge badge-warning text-head ml-2">
                inspector
              </div>
              <span className="text-head ml-1">{inspector}</span>
              <div className="badge badge-danger text-head ml-2">qa</div>
              <span className="text-head ml-1">{qa}</span>
              <span className="badge badge-dark text-head ml-2">
                {createdAt}
              </span>
            </small>
          </h2>
          <div
            className="img-annotated"
            style={{ overflow: 'hidden' }}
            id="img-annotated"
          >
            <PrismaZoom>
              <img
                src={taskInspect.image_cap}
                width="100%"
                height="auto"
                alt="Agent"
                style={{
                  transform: `rotate(${rotate}deg)`,
                  width: '100%',
                  height: `${
                    taskInspect.photoCode === '01' ? 'calc(90vh)' : '100%'
                  }`,
                }}
                className="img-lazy-load"
              ></img>
              {damagePoint &&
                damagePoint.length > 0 &&
                showDamagesPoint &&
                damagePoint.map((item, index) => {
                  return (
                    <Rnd
                      className="damage-point"
                      key={`Damage_${index}_agent`}
                      id={`rnd-${index}-agent`}
                      style={{
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '0 0',
                        backgroundSize: 'contain',
                        zIndex: 999,
                      }}
                      position={{
                        x: item?.x ?? 0,
                        y: item?.y ?? 0,
                      }}
                      lockAspectRatio={true}
                      enableResizing={false}
                      disableDragging={true}
                    >
                      <div className="admin-point">{index + 1}</div>
                      {item.source === 'user' ||
                      (group === 'AMZ' &&
                        item.username === inspector &&
                        item.admin_response_taskFailed !== 'reject' &&
                        item.status !== 'repaired') ? (
                        <i
                          className="far fa-square"
                          style={{
                            color: item.strokeColor,
                          }}
                        ></i>
                      ) : item.admin_response_taskFailed === 'reject' ||
                        item.user_response === 'reject' ||
                        item.status === 'repaired' ? (
                        <i
                          className={
                            item.username === inspector
                              ? 'fas fa-square'
                              : `fas fa-exclamation-triangle`
                          }
                          style={{
                            color: item.strokeColor,
                          }}
                        ></i>
                      ) : item.admin_response_taskFailed === 'add' ? (
                        <i
                          className="far fa-circle"
                          style={{
                            color: item.strokeColor,
                          }}
                        ></i>
                      ) : item.admin_response_taskFailed === 'edit' ? (
                        <i
                          className="far fa-dot-circle"
                          style={{
                            color: item.strokeColor,
                          }}
                        ></i>
                      ) : (
                        <i
                          className="far fa-square"
                          style={{
                            color: item.strokeColor,
                          }}
                        ></i>
                      )}
                    </Rnd>
                  );
                })}
            </PrismaZoom>
          </div>
          <Notes notes={notes} timezone={timezone} />
        </div>
        <div className="col-12 col-sm-4">
          <TitleInspect
            taskInspect={taskInspect}
            taskSettings={taskSetting}
            inspectionInspect={inspectionInspect}
          />
          <InfoBar
            vehicle={vehicle}
            taskInspect={taskInspect}
            imgSupport={imgSupport}
            support={support}
            inspectionInspect={inspectionInspect}
          />
          <div
            style={{
              display: 'flex',
              fontWeight: 'bold',
            }}
            className="mb-2"
          >
            <div
              className="title mr-2"
              style={{
                padding: '4px 10px',
                background: '#321342',
                borderRadius: '5px',
                display: 'inline-block',
                color: '#fff',
                fontSize: '13px',
              }}
            >
              SESSIONID
            </div>
            <span
              className="text-primary"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => newTab(taskFailed.infor.sessionID)}
            >
              {inspectionInspect.sessionID}
            </span>
          </div>
          {toolHotKey()}
          <Action
            taskFailed={taskFailed}
            taskSetting={taskSetting}
            deleteTaskFailed={deleteTaskFailed}
            user={user}
            setqaData={setqaData}
          />
          <InspectorData taskFailed={taskFailed} vehData={vehData} />
          <QaData
            user={user}
            qaData={qaData}
            setqaData={setqaData}
            vehData={vehData}
          />
          {renderMessBox()}
          {renderConfirmButton()}
          {modalImage ? <ModalImage support={support} /> : null}
          {modalImageFull ? (
            <ModalImageFull
              src={imgSupport}
              photoCode={taskInspect.photoCode}
            />
          ) : null}
        </div>
      </div>
      {showwarning ? (
        <ModalShowErr
          messErr={messwarning}
          resetError={() => setshowwarning(false)}
        />
      ) : null}
      <ModalConfirm
        show={showmodalConfirm}
        hideModal={() => setshowmodalConfirm(false)}
        handle={confirmData}
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  taskFailed: state.taskFailed,
});

export default connect(mapStateToProps, {
  getTaskFailed,
  deleteTaskFailed,
  confirmTaskFailed,
})(TaskFailedView);
