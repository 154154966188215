import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { statusOptions } from '../../settings';
import { Tooltip } from 'reactstrap';
import CountDown from './partials/CountDown';
const displayDurationTime = (
  timeProcess,
  timeFinish,
  priority_ttc,
  session,
) => {
  const processAt = new Date(timeProcess);
  const finishAt = new Date(timeFinish);
  let type = 'dark';
  const diff = finishAt.getTime() - processAt.getTime();
  if (session) {
    if (diff > priority_ttc) {
      type = 'danger';
    }
  }
  const inSeconds = Math.round(diff / 1000);
  const inMinutes = Math.floor(inSeconds / 60);
  const secondLeft = inSeconds - inMinutes * 60;
  return (
    <div
      style={{
        fontSize: '16px',
      }}
      className={`badge badge-pill d-flex align-items-center text-head badge-${type} mr-1`}
    >
      <span className="mr-1">Duration</span>
      <span className="mr-1">:</span>
      <span>{inMinutes}min</span>
      <span>{secondLeft}s</span>
    </div>
  );
};
const InspectionListTableItem = ({
  data,
  view,
  auth,
  inspectInspection,
  checkInspection,
  manage,
  showModalConfirm,
  setModalCage,
  rd,
}) => {
  const newTab = (id) => {
    const link = window.location.origin;
    let url = link + `/inspectionView/${id}`;
    const win = window.open(url, '_blank');
    win.focus();
  };
  const [tooltipOpenQC, setTooltipOpenQC] = useState(false);
  const [tooltipOpenIns, setTooltipOpenIns] = useState(false);

  const themeName = data?.inspectionThemes?.name ?? 'DEFAULT';
  const themeColor = data?.inspectionThemes?.themeColor ?? '';
  const statusOption = statusOptions?.[data?.status] ?? {};
  const hasTimeOut = data?.hasTimeOut ?? false;
  const qcPassedAt = data?.qcPassedAt ? new Date(data.qcPassedAt) : null;
  const autoInspectedAt = data?.autoInspectedAt
    ? new Date(data.autoInspectedAt)
    : null;
  const autoCheckedAt = data?.autoCheckedAt
    ? new Date(data.autoCheckedAt)
    : null;
  const autoValidatedAt = data?.autoValidatedAt
    ? new Date(data.autoValidatedAt)
    : null;
  const autoFinishedAt = data?.autoFinishedAt
    ? new Date(data.autoFinishedAt)
    : null;
  const priority_ttc = data?.options?.priority_ttc
    ? data.options.priority_ttc * 1000
    : 900 * 1000;
  const validateSuccessedAt = data?.validateSuccessedAt
    ? new Date(data.validateSuccessedAt)
    : null;
  const inspectedAt = data?.inspectedAt ? new Date(data.inspectedAt) : null;
  const inspectSuccessedAt = data?.inspectSuccessedAt
    ? new Date(data.inspectSuccessedAt)
    : null;
  const checkedAt = data?.inspectedAt ? new Date(data.inspectedAt) : null;
  const checkSuccessedAt = data?.checkSuccessedAt
    ? new Date(data.checkSuccessedAt)
    : null;
  const finishedAt = data?.finishedAt ? new Date(data.finishedAt) : null;
  const processAt = data?.processAt
    ? new Date(data.processAt)
    : finishedAt
    ? new Date(new Date(data.finishedAt).getTime() + priority_ttc)
    : null;
  const inspector = data?.inspector ?? '';
  const qa = data?.qa ?? '';
  const manager = data?.manager ?? '';
  const validator = data?.validator ?? '';
  const role = auth?.user?.role ?? '';
  const username = auth?.user?.username ?? '';
  let checkShowCage = true;
  let checkShowUnInspect = false;
  let checkShowUnCheck = false;
  let checkShowManage = false;
  let checkShowUnManage = false;
  let showClockQcPassed = false;
  let showClockFull = false;
  let showClockAgent = false;
  let showClockQa = false;
  let showClockValidator = false;
  if (['AGENT', 'QA'].includes(role)) {
    if (username === inspector) {
      if (!inspectSuccessedAt) {
        showClockAgent = true;
      }
    }
    if (username === qa) {
      if (!finishedAt) {
        showClockQa = true;
      }
    }
    if (username === validator) {
      if (!validateSuccessedAt) {
        showClockValidator = true;
      }
    }
  }
  if (['SUPER', 'TRAINER', 'ADMIN'].includes(role)) {
    if (!finishedAt) {
      showClockFull = true;
      showClockQcPassed = true;
    }
    checkShowManage = true;
    checkShowUnManage = true;
    checkShowUnCheck = true;
    checkShowUnInspect = true;
    if (['FINISHED', 'COMPLETED'].includes(data.status)) {
      checkShowUnInspect = false;
      checkShowUnCheck = false;
    }
  }

  const timezone = auth?.user?.options?.timezone ?? 'Asia/Ho_Chi_Minh';
  const vehicle = data?.vehicle ?? false;
  const cage = data?.cage ?? false;
  const listPhotos = data?.listPhotos ?? [];
  const qcProgress = data?.qcProgress?.length ? data.qcProgress.sort() : [];
  const inspectProgress = data?.inspectProgress?.length
    ? data.inspectProgress.sort()
    : [];
  let perQc = 0;
  let perIns = 0;
  if (qcProgress && qcProgress.length) {
    perQc = ['INSPECTED', 'CONFIRMED', 'FINISHED', 'COMPLETED'].includes(
      data.status,
    )
      ? 100
      : Math.ceil((qcProgress.length * 100) / 13);
  }
  if (inspectProgress && inspectProgress.length) {
    perIns = ['INSPECTED', 'CONFIRMED', 'FINISHED', 'COMPLETED'].includes(
      data.status,
    )
      ? 100
      : Math.ceil((inspectProgress.length * 100) / 13);
  }
  let displayVeh, displayCage;
  if (vehicle) {
    let style = {
      cursor: 'pointer',
      position: 'absolute',
      top: '10px',
      right: '10px',
      fontSize: '18px',
    };
    if (data.standard) {
      style = { ...style, color: 'green' };
    }
    displayVeh = (
      <div className="display-8">
        <b className="text-head">
          {vehicle.year} {vehicle.make} {vehicle.model}
        </b>
        <br />
        {vehicle.bodyType}
        <br />
        {vehicle.vin && vehicle.vin.toUpperCase()
          ? `VIN: ${vehicle.vin.toUpperCase()} `
          : vehicle.vrm && vehicle.vrm.toUpperCase()
          ? `PLATE: ${vehicle.vrm.toUpperCase()}`
          : ''}
        {['INSPECTED', 'CONFIRMED', 'FINISHED', 'COMPLETED'].includes(
          data.status,
        ) ? (
          role === 'SUPER' && !data.standard ? (
            <i
              onClick={() => showModalConfirm(data.sessionID, data.standard)}
              style={style}
              className="fas fa-question ml-1"
            ></i>
          ) : null
        ) : null}

        {data.sameInspection || (data.standard && data.standard.standard) ? (
          <>
            <i
              className={`fas fa-star ${
                data.standard && data.standard.standard
                  ? 'text-success'
                  : 'text-warning'
              } ml-1`}
              style={{
                fontSize: '16px',
                cursor: role === 'SUPER' ? 'pointer' : '',
              }}
              onClick={
                role === 'SUPER'
                  ? () =>
                      showModalConfirm(
                        data.sessionID,
                        data.standard && data.standard.standard
                          ? data.standard.data.standard
                          : false,
                      )
                  : null
              }
            ></i>
            {data.standard && data.standard.manual && data.standard.standard ? (
              <div className="badge badge-primary text-head ml-1">manual</div>
            ) : null}
          </>
        ) : null}
      </div>
    );
  }
  if (cage) {
    displayCage = (
      <div className="display-8">
        <b className="text-head">
          {cage.year} {cage.make} {cage.model}
        </b>
        <br />
        {cage.bodyType}
        <br />
        {checkShowCage ? (
          <button
            style={{ float: 'right' }}
            className="btn btn-tiny btn-warning"
            onClick={() => setModalCage(data)}
          >
            <i className="fa fa-pen" />
          </button>
        ) : null}
      </div>
    );
  }
  let viewStyle;
  if (view) {
    viewStyle = (
      <span
        className="text-head text-primary cursor-pointer"
        onClick={() => view(data.inspectionID)}
      >
        {data.inspectionID}
      </span>
    );
  } else {
    viewStyle = (
      <Link
        to={`/inspectionView/${data._id}`}
        className="text-head text-primary"
      >
        {data.inspectionID}
      </Link>
    );
    if (rd) {
      viewStyle = (
        <div
          style={{
            display: 'inline-block',
            cursor: 'pointer',
            fontWeight: 'bold',
          }}
          className="text-primary"
          onClick={() => newTab(data._id)}
        >
          {data.inspectionID}
        </div>
      );
    }
  }
  return (
    <tr>
      <td width="6%">
        <div
          className={`badge ${
            data.group === 'AMZ' ? 'badge-primary' : 'badge-success'
          } text-uppercase`}
          style={{ width: '100%' }}
        >
          <span className="text-head">
            {data.group ? data.group : 'DEFAULT'}
          </span>
        </div>
        <div
          className={`badge text-uppercase badge-primary mt-1`}
          style={{ background: `${themeColor}`, width: '100%' }}
        >
          <span className="text-head">{themeName}</span>
        </div>
      </td>
      <td width="8%">
        {manager ? (
          <div className="">
            <div
              className={`badge badge-danger mr-1 d-inline `}
              style={{ width: '100%' }}
            >
              <span className="text-head">{manager}</span>
              {!['FINISHED', 'COMPELTED'].includes(data.status) &&
              checkShowUnManage ? (
                <i
                  className="fas fa-times ml-1"
                  style={{ cursor: 'pointer' }}
                  onClick={() => manage(data.sessionID, 'unmanage')}
                ></i>
              ) : null}
            </div>
          </div>
        ) : checkShowManage ? (
          <button
            className="btn btn-tiny btn-dark"
            onClick={() => manage(data.sessionID, 'manage')}
          >
            <i className="fas fa-clipboard-check"></i>
          </button>
        ) : null}
        {validator ? (
          <div className="mt-1">
            <div
              className={`badge  badge-warning mr-1 d-inline`}
              style={{ width: '100%' }}
            >
              <span className="text-head">{validator}</span>
            </div>
          </div>
        ) : null}
      </td>
      <td width="8%">
        {qa ? (
          <div className="">
            <div
              className={`badge  badge-warning mr-1 d-inline`}
              style={{ width: '100%' }}
            >
              <span className="text-head">{qa}</span>
              {!['FINISHED', 'COMPELTED'].includes(data.status) &&
              checkShowUnCheck ? (
                <i
                  className="fas fa-times ml-1"
                  style={{ cursor: 'pointer' }}
                  onClick={() => checkInspection(data.sessionID, 'un-check')}
                ></i>
              ) : null}
            </div>
          </div>
        ) : null}
        {inspector ? (
          <div className="mt-1">
            <div
              className={`badge  badge-warning mr-1 d-inline`}
              style={{ width: '100%' }}
            >
              <span className="text-head">
                {inspector[0].username || inspector}
              </span>
              {!['COMPLETED', 'FINISHED'].includes(data.status) &&
              checkShowUnInspect ? (
                <i
                  className="fas fa-times ml-1"
                  style={{ cursor: 'pointer' }}
                  onClick={() => inspectInspection(data.sessionID, 'unInspect')}
                ></i>
              ) : null}
            </div>
          </div>
        ) : null}
      </td>
      <td width="9%">
        <div
          className={`badge badge-bullet text-uppercase badge-${
            statusOption.style || 'secondary'
          } mb-1 mr-1`}
          style={{
            background: data.status === 'COMPLETED' ? '#321640' : '',
          }}
        >
          <span className="text-head">{statusOption.label || data.status}</span>
        </div>
        {hasTimeOut && ['TRAINER', 'SUPER'].includes(role) ? (
          <div
            className={`badge badge-bullet text-uppercase badge-danger  mb-1 mr-1`}
          >
            <span className="text-head">TimeoutQC</span>
          </div>
        ) : null}
        {/* {hasTimeOutInspect && role === 'SUPER' ? (
					<div
						className={`badge badge-bullet text-uppercase badge-danger  mb-1 mr-1`}
					>
						<span className="text-head">TimeoutIns</span>
					</div>
				) : null} */}
      </td>

      <td width="12%">
        {viewStyle}
        <br />/ {data.sessionID}
        {data.options && data.options.priority_level ? (
          <div
            className={`badge mt-1 d-flex text-head align-items-center text-center justify-content-center ${
              ['F1'].includes(data.options.priority_level)
                ? 'badge-warning'
                : 'badge-dark'
            }`}
          >
            {data.options.priority_level}
          </div>
        ) : null}
        {data.predict ? (
          <div
            className={`badge badge-primary mt-1 d-flex text-head align-items-center text-center justify-content-center`}
          >
            Predict
          </div>
        ) : null}
      </td>
      <td width="8%">
        {listPhotos.sort().map((item, index) => {
          return (
            <div
              key={`photos_${index}`}
              className="photos btn-secondary mr-1 mb-1"
              style={{
                display: 'inline-block',
                fontSize: '11px',
                padding: '1px 5px',
                borderRadius: '5px',
                fontWeight: 'bold',
              }}
            >
              {item}
            </div>
          );
        })}
        <div
          className="text-head"
          style={{
            width: '100%',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '11px',
          }}
        >
          <div style={{ width: '100%' }}>
            <div>QC</div>
            <div
              className="progress"
              id={`qc_${data.sessionID}`}
              onClick={() => setTooltipOpenQC(!tooltipOpenQC)}
              style={{ cursor: 'pointer', margin: '0 5px' }}
            >
              <div
                className={`progress-bar ${
                  perQc >= 100 ||
                  ['INSPECTED', 'CONFIRMED', 'FINISHED', 'COMPLETED'].includes(
                    data.status,
                  )
                    ? 'bg-success'
                    : 'bg-primary progress-bar-striped progress-bar-animated'
                }`}
                role="progressbar"
                style={{
                  width: `${perQc}%`,
                  fontWeight: 'bold',
                }}
                aria-valuenow={perQc}
                aria-valuemin="0"
                aria-valuemax={perQc}
              >
                {qcProgress && qcProgress.length ? qcProgress.length : 0}
              </div>
            </div>
            {qcProgress && qcProgress.length ? (
              <Tooltip
                placement="top"
                isOpen={tooltipOpenQC}
                target={`qc_${data.sessionID}`}
              >
                {qcProgress.map((item, index) => {
                  return (
                    <div
                      style={{
                        display: 'inline-block',
                        padding: '3px 5px',
                        marginRight: '3px',
                        fontSize: '10px',
                        marginBottom: '1px',
                        background: '#28a745',
                        color: '#fff',
                        borderRadius: '5px',
                      }}
                      className="item"
                      key={'qc_' + index}
                    >
                      {item}
                    </div>
                  );
                })}
              </Tooltip>
            ) : null}
          </div>
          <div style={{ width: '100%' }}>
            <div>INSPECT</div>
            <div
              className="progress"
              id={`ins-${data.inspectionID}`}
              onClick={() => setTooltipOpenIns(!tooltipOpenIns)}
              style={{ cursor: 'pointer', margin: '0 5px' }}
            >
              <div
                className={`progress-bar ${
                  perIns >= 100 ||
                  ['INSPECTED', 'CONFIRMED', 'FINISHED', 'COMPLETED'].includes(
                    data.status,
                  )
                    ? 'bg-success'
                    : 'bg-primary progress-bar-striped progress-bar-animated'
                }`}
                role="progressbar"
                style={{
                  width: `${perIns}%`,
                  fontWeight: 'bold',
                }}
                aria-valuenow={perIns}
                aria-valuemin="0"
                aria-valuemax={perIns}
              >
                {inspectProgress && inspectProgress.length
                  ? inspectProgress.length
                  : 0}
              </div>
            </div>
            {inspectProgress && inspectProgress.length ? (
              <Tooltip
                placement="top"
                isOpen={tooltipOpenIns}
                target={`ins-${data.inspectionID}`}
              >
                {inspectProgress.map((item, index) => {
                  return (
                    <div
                      style={{
                        display: 'inline-block',
                        padding: '3px 5px',
                        marginRight: '3px',
                        marginBottom: '1px',
                        fontSize: '10px',
                        background: '#28a745',
                        color: '#fff',
                        borderRadius: '5px',
                      }}
                      className="item"
                      key={'inspect-' + index}
                    >
                      {item}
                    </div>
                  );
                })}
              </Tooltip>
            ) : null}
          </div>
        </div>
      </td>
      <td width="15%">{displayVeh}</td>
      <td width="15%">{displayCage}</td>
      <td width="11%">
        <small>
          <i className="fa fa-fw fa-play text-primary mr-2"></i>
          {new Date(data.createdAt).toLocaleString('en-US', {
            timeZone: timezone,
          })}
        </small>
        <br />
        {data.finishedAt && (
          <small>
            <i className="fa fa-fw fa-clipboard-check text-success mr-2"></i>
            {new Date(data.finishedAt).toLocaleString('en-US', {
              timeZone: timezone,
            })}
            <br />
          </small>
        )}
        {data.updatedAt && (
          <small>
            <i className="fa fa-fw fa-edit text-secondary mr-2"></i>
            {new Date(data.updatedAt).toLocaleString('en-US', {
              timeZone: timezone,
            })}
          </small>
        )}
      </td>
      <td width="8%">
        {showClockQcPassed && qcPassedAt ? (
          <CountDown end={qcPassedAt} priority_ttc={priority_ttc} />
        ) : null}
        {showClockFull && autoFinishedAt ? (
          <CountDown end={autoFinishedAt} />
        ) : null}
        {showClockAgent && autoInspectedAt ? (
          <CountDown end={autoInspectedAt} />
        ) : null}
        {showClockQa && autoCheckedAt ? (
          <CountDown end={autoCheckedAt} />
        ) : null}
        {showClockValidator && autoValidatedAt ? (
          <CountDown end={autoValidatedAt} />
        ) : null}
        {role === 'AGENT' && inspectedAt && inspectSuccessedAt
          ? displayDurationTime(inspectedAt, inspectSuccessedAt)
          : null}
        {role === 'QA' && checkedAt && checkSuccessedAt
          ? displayDurationTime(checkedAt, checkSuccessedAt)
          : null}
        {!['AGENT', 'QA'].includes(role) && processAt && finishedAt
          ? displayDurationTime(qcPassedAt, finishedAt, priority_ttc, true)
          : null}
      </td>
    </tr>
  );
};

export default InspectionListTableItem;
