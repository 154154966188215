export const genPhoto = (item) => {
  const urlDynamic = process.env.REACT_APP_PAVE_DYNAMIC_PHOTO_ROOT;
  const original = item?.url_builder?.o ?? null;
  const bucket = item?.url_builder?.b ?? null;
  const image_cap = item?.image_cap ?? '';
  const linkUrlPhoto = item?.url_builder
    ? urlDynamic + `?` + `&b=${bucket}` + `&o=${original}`
    : image_cap;
  return linkUrlPhoto;
};
