import React from 'react';
//import Timeline from '../components/partials/Timeline';
import InspectData from './InspectData';
const displayDurationTime = (duration, user) => {
  let type = 'dark';
  if (user === 'inspect') {
    type = 'primary';
  }
  if (user === 'check') {
    type = 'warning';
  }
  if (user === 'manage') {
    type = 'dark';
  }
  const inMinutes = Math.floor(duration / 60);
  const secondLeft = Math.floor(duration - inMinutes * 60);
  return (
    <div className="d-flex duration-time mb-1">
      <div
        style={{
          fontSize: '13px',
        }}
        className={`badge text-uppercase text-head badge-${type} mr-1`}
      >
        <span className="mr-1">{user}:</span>
        <span>{inMinutes}min</span>
        <span>{secondLeft}s</span>
      </div>
    </div>
  );
};
const ProcessData = (props) => {
  const {
    step,
    task,
    inspection,
    user,
    showReject,
    showInspect,
    showModalFinal,
    damage_table,
    showReport,
  } = props;
  let { qcPassed } = props;
  const statusInspection = inspection?.status ?? '';
  const statusTask = task.status ?? '';
  const durationInspect = task?.durationInspect ?? 0;
  const durationCheck = task?.durationCheck ?? 0;
  const durationManage = task?.durationManage ?? 0;
  const aiData = task?.aiData ?? null;
  const skillsUser = user?.skills ?? [];
  let timeInspect = task?.timeInspect ?? 0;
  let timeCheck = task?.timeCheck ?? 0;
  let showAi = false;
  if (
    skillsUser.includes('AI') ||
    ['TRAINER', 'SUPER', 'ADMIN'].includes(user.role)
  ) {
    showAi = true;
  }

  const uiTimerTask = () => {
    return (
      <div>
        {timeInspect ? (
          <div
            className={`timer d-block badge badge-primary text-head mb-1 `}
            style={{
              fontSize: '13px',
            }}
          >
            <i className="fas fa-history"></i>
            <span className="ml-1">{Math.floor(timeInspect)} s</span>
          </div>
        ) : null}
        {timeCheck ? (
          <div
            className={`timer d-block badge badge-warning text-head `}
            style={{
              fontSize: '13px',
            }}
          >
            <i className="fas fa-history"></i>
            <span className="ml-1">{Math.floor(timeCheck)} s</span>
          </div>
        ) : null}
      </div>
    );
  };

  const uiAiSuggest = (aiData) => {
    const damage_count = aiData?.damage_count?.total ?? 0;
    if (aiData) {
      return (
        <div className="ai mt-1 text-head">
          <div className="badge badge-primary d-block">
            <i className="fas fa-laptop-code mr-1"></i>
            {damage_count} damages
          </div>
        </div>
      );
    }
    return null;
  };
  const uiStatusTask = (status) => {
    let i = 'fas fa-spinner fa-spin';
    let color = 'primary';
    if (status === 'FINISHED') {
      i = 'fas fa-check';
      color = 'success';
    }
    return (
      <div
        className={`badge badge-pill badge-${color} text-head d-block `}
        style={{
          fontSize: '20px',
        }}
      >
        <i className={i}></i>
        <span>{status}</span>
      </div>
    );
  };

  const checkSkipQC =
    inspection &&
    inspection.options &&
    inspection.options.customized &&
    inspection.options.customized.custom_skip_qc &&
    inspection.options.customized.custom_skip_qc.length > 0
      ? inspection.options.customized.custom_skip_qc.map((item) => {
          if (item < 10) {
            return `0${item}`;
          } else {
            return `${item}`;
          }
        })
      : [];
  const usecase = inspection?.options?.usecase ?? null;
  const qc_only_accept = inspection?.options?.qc_only_accept ?? null;
  const inspector = inspection?.inspector ?? '';
  const qa = inspection?.qa ?? '';
  const manager = inspection?.manager ?? '';
  const group = inspection?.group ?? 'DEFAULT';
  const holding = inspection?.holding ?? false;
  const role = user?.role ?? 'AGENT';
  const username = user?.username ?? '';
  const theme = inspection?.inspectionThemes?.name ?? '';
  const decodeFailed =
    usecase === 'holman' && task?.options?.decodeFailed
      ? task.options.decodeFailed
      : false;

  if (['AMZ', 'AMZ-1', 'AMZ-2', 'AMZ-3'].includes(group)) {
    qcPassed = false;
  }
  let checkShowEdit = false;
  let checkShowReject = false;
  let showButtonReport = false;
  if (['QA', 'AGENT'].includes(role)) {
    if (username === inspector && statusInspection === 'INSPECTING') {
      checkShowEdit = true;
      checkShowReject = true;
      showButtonReport = true;
    }
    if (username === qa && statusInspection === 'CHECKING') {
      checkShowEdit = true;
      checkShowReject = true;
      showButtonReport = true;
    }
    if (holding) {
      checkShowEdit = false;
      checkShowReject = false;
      showButtonReport = true;
    }
    if (manager) {
      checkShowEdit = false;
      checkShowReject = false;
      showButtonReport = false;
    }
    if (['VIN-CONFIRM', 'VIN-CHECKING'].includes(inspection.status)) {
      checkShowEdit = false;
      checkShowReject = false;
      showButtonReport = false;
    }
  }
  if (['SUPER', 'TRAINER', 'ADMIN'].includes(role)) {
    showButtonReport = true;
    if (username === manager) {
      checkShowEdit = true;
      checkShowReject = true;
    }
  }
  if (['01', '10', '11', '12', '13'].includes(task.photoCode)) {
    checkShowEdit = false;
  }
  if (theme === 'CAPT' && task.photoCode !== '03') {
    checkShowEdit = false;
  }
  const urlDynamic = process.env.REACT_APP_PAVE_DYNAMIC_PHOTO_ROOT;
  const original = task?.url_builder?.o ?? null;
  const bucket = task?.url_builder?.b ?? null;
  const linkUrlPhoto = task.url_builder
    ? urlDynamic + `?` + `&b=${bucket}` + `&o=${original}`
    : task.image_cap;
  const linkUrlPhotoWebp = task.url_builder
    ? urlDynamic + `?` + `&b=${bucket}` + `&o=${original}` + `&p=100`
    : `${task.image_cap}.webp`;
  if (task)
    return (
      <div className="ml-2" key={`processPhoto_` + task.photo_id}>
        <div className="process" style={{ display: 'flex' }}>
          <div
            className="svg d-flex justify-content-center"
            style={{ width: '10%' }}
          >
            <div className="group-image">
              <img
                style={{
                  width: '96%',
                }}
                src={step.svg}
                alt="svg-outline"
              ></img>
            </div>
          </div>
          <div
            className="groupImage justify-content-center"
            style={{
              display: 'flex',
              borderRadius: '5px',
              width: '5%',
            }}
          >
            <div className="image">
              <div
                className="btn-group"
                style={{
                  background: '#32164017',
                  borderTopLeftRadius: '5px',
                  borderTopRightRadius: '5px',
                  width: '100%',
                }}
              >
                {task.image_cap && (
                  <button
                    className="btn btn-tiny btn-light"
                    onClick={() => {
                      showModalFinal(task, linkUrlPhotoWebp);
                    }}
                  >
                    <span
                      style={{
                        fontSize: '12px',
                        textAlign: 'center',
                      }}
                    >
                      Webp
                    </span>
                  </button>
                )}
                {task.image_cap && (
                  <button
                    className="btn btn-tiny btn-light"
                    onClick={() => {
                      showModalFinal(task, linkUrlPhoto);
                    }}
                  >
                    <span
                      style={{
                        fontSize: '12px',
                        textAlign: 'center',
                      }}
                    >
                      Img
                    </span>
                  </button>
                )}
              </div>
              {['02', '04', '05', '07', '08', '09', '14'].includes(
                task.photoCode,
              ) && (
                <button
                  className="btn btn-tiny btn-block btn-light btn-lb-dark mt-1"
                  onClick={() => showModalFinal(task, linkUrlPhoto, true, true)}
                >
                  <span
                    style={{
                      fontSize: '12px',
                      textAlign: 'center',
                    }}
                  >
                    Full
                  </span>
                </button>
              )}

              {task.annotated_images && (
                <button
                  className="btn btn-tiny btn-light btn-lb-dark mt-1"
                  onClick={() =>
                    showModalFinal(
                      task,
                      task.annotated_images,
                      false,
                      false,
                      true,
                    )
                  }
                  style={{
                    width: '100%',
                  }}
                >
                  <span
                    style={{
                      fontSize: '12px',
                      textAlign: 'center',
                    }}
                  >
                    Final
                  </span>
                </button>
              )}
              <div className="photoId badge badge-dark d-block text-head">
                {task.photo_id}
              </div>
            </div>
          </div>

          <div
            className="informationTask d-flex"
            style={{
              width: '12%',
            }}
          >
            {task.photo_id ? (
              <div className="action d-inline-block">
                <div className="btn-group d-block mb-1">
                  {checkShowEdit &&
                    task.photoCode !== '01' &&
                    !['TRAINEE', 'VIEWER'].includes(user.role) &&
                    ![
                      'INSPECTED',
                      'CONFIRMED',
                      'FINISHED',
                      'COMPLETED',
                    ].includes(statusInspection) && (
                      <div
                        className="badge badge-primary text-head mr-1"
                        onClick={() => showInspect(task._id)}
                        style={{
                          cursor: 'pointer',
                          fontSize: '17px',
                        }}
                      >
                        <i className="fa fa-edit" />
                      </div>
                    )}
                  {checkShowReject &&
                    !qcPassed &&
                    !decodeFailed &&
                    !checkSkipQC.includes(task.photoCode) &&
                    !['TRAINEE', 'VIEWER'].includes(user.role) &&
                    !qc_only_accept &&
                    ![
                      'INSPECTED',
                      'CONFIRMED',
                      'FINISHED',
                      'COMPLETED',
                    ].includes(statusInspection) && (
                      <div
                        className="badge badge-danger text-head mr-1"
                        onClick={() => showReject(task.photoCode, task._id)}
                        style={{
                          cursor: 'pointer',
                          fontSize: '17px',
                        }}
                      >
                        <i className="fa fa-ban" />
                      </div>
                    )}
                  {showButtonReport && (
                    <div
                      className="badge badge-warning text-head mr-1"
                      onClick={() => showReport(task.photoCode, task._id)}
                      style={{
                        cursor: 'pointer',
                        fontSize: '17px',
                      }}
                    >
                      <i className="fas fa-flag-checkered" />
                    </div>
                  )}
                </div>
                <div className="status d-block mb-1">
                  {uiStatusTask(statusTask)}
                </div>
                <div className="qc d-block">
                  <div
                    className="badge badge-warning text-head mr-1"
                    style={{
                      fontSize: '17px',
                    }}
                  >
                    QC - {task.qc}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="timer mr-2" style={{ width: '7%' }}>
            {uiTimerTask()}
            {showAi && aiData ? (
              <div className="AI">
                <span className="badge badge-warning text-head ">
                  AI SUGGEST
                </span>
                {uiAiSuggest(aiData)}
              </div>
            ) : null}
          </div>
          <div className="time-line" style={{ width: '8%' }}>
            {durationInspect
              ? displayDurationTime(durationInspect, 'inspect')
              : null}
            {durationCheck ? displayDurationTime(durationCheck, 'check') : null}
            {durationManage
              ? displayDurationTime(durationManage, 'manage')
              : null}
          </div>
          <div className="inspectdata ml-2" style={{ width: '58%' }}>
            <InspectData
              data={task}
              vehicle={inspection.vehicle || {}}
              damage_table={damage_table}
            />
          </div>
        </div>
      </div>
    );
};

export default ProcessData;
