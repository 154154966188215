import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  statusStart,
  statusWaitting,
  finishTask,
  timeOutTask,
  getTask,
  resetAll,
  getDamages,
  clearlistDamages,
  getDamagesTire,
  getlistTrims,
  acceptImageRequest,
  rejectImageRequest,
  clearMessageTask,
  getInteriorData,
} from './../operations';
import SVG from 'react-inlinesvg';
import DamageView from './components/DamageView';
import Inspect from './components/Inspect';
import ModalImage from './components/ModalImage';
import ModalWatting from './components/ModalWatting';
import TitleInspect from './components/TitleInspect';
import InfoBar from './components/InfoBar';
import PhotoThumb from './components/PhotoThumb';
import PhotoFull from './components/PhotoFull';
import { logout } from '../../auth/operations';
import $ from 'jquery';
import ModalCheckAdmin from './components/ModalCheckAdmin';
import ModalShowErr from './components/ModalShowErr';
import Modal from './components/Modal';
import ModalImageFull from './components/ModalImageFull';
import FinishButton from './components/FinishButton';
import ModalCheckSameInspection from './components/ModalCheckSameInspection';
import ModalMD from '../../inspections/containers/components/partials/ModalMD';
import { caculatePostionShow } from '../../../utils/position';
import uniqid from 'uniqid';
import { DAME_TYPES } from '../constants';
import { cloneDeep } from 'lodash';
window.$ = $;

const Process = (props) => {
  const [imgW, setimgW] = useState(0);
  const [imgH, setimgH] = useState(0);
  const [showImgInspect, setshowImgInspect] = useState(false);
  const [cagePo, setcagePo] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const svgRef = useRef(null);

  const [img, setimg] = useState('');
  const [showBtnFinish, setshowBtnFinish] = useState(false);
  const [showInspectInfo, setshowInspectInfo] = useState(false);
  const [showSvg, setshowSvg] = useState(false);
  const [btnConfirm, setbtnConfirm] = useState({
    title: '',
    name: '',
    des: '',
  });
  const [showWarning, setshowWarning] = useState(false);
  const [messWarning, setmessWarning] = useState('');
  const [showConfirm, setshowConfirm] = useState(false);
  const [waitting, setwaitting] = useState(false);
  let [dataInspect, setdataInspect] = useState(null);
  let [damages, setdamages] = useState([]);
  const [damagesInspected, setdamagesInspected] = useState([]);
  const [rotate, setrotate] = useState(0);
  const [zoom, setzoom] = useState(1);
  const [scale, setscale] = useState(1);
  const [show, setshow] = useState(false);
  const [cageShow, setcageShow] = useState(true);
  const [disableDragging, setdisableDragging] = useState(false);
  const [support, setsupport] = useState({ support: '', index: 0 });
  const [modalImage, setmodalImage] = useState(false);
  const [modalImageFull, setmodalImageFull] = useState(false);
  const [modalErr, setmodalErr] = useState(false);
  const [imgDone, setimgDone] = useState(false);
  const [modalCheckSameInspection, setmodalCheckSameInspection] =
    useState(false);
  const [modalShowTimeOutSession, setmodalShowTimeOutSession] = useState(false);
  const [runOneTime, setrunOneTime] = useState(false);
  const [runOneTimeFinish, setrunOneTimeFinish] = useState(false);
  const [showAIDamage, setshowAIDamage] = useState(false);
  const [showAICage, setshowAICage] = useState(false);
  const [showDamages, setshowDamages] = useState(true);
  const [listInterior, setlistInterior] = useState([]);
  const [loadInterior, setloadInterior] = useState(false);
  const [currentIndexInterior, setcurrentIndexInterior] = useState(0);
  let { inspection, auth } = props;
  let { adminTask } = inspection;
  const sameTask = adminTask?.sameTask ?? null;
  const taskId = props?.taskId ?? '';
  let taskInspect = adminTask?.task ?? null;
  let inspectionInspect = adminTask?.inspection ?? {};
  let vehicle = adminTask?.vehicle ?? null;
  let cageSvg = adminTask?.cage ?? null;
  const taskSetting = adminTask?.taskSetting ?? {};
  let listDamages = inspection?.listDamages ?? [];
  let imgSupport = taskInspect?.options?.supportView ?? false;
  let unauthorized = inspection?.error?.response?.data ?? null;
  let error = inspection?.error?.response?.status ?? null;
  const sessionId = inspectionInspect?.sessionID ?? null;
  const checkTest = sessionId ? sessionId.startsWith('T') : null;
  let themeColor = inspectionInspect?.inspectionThemes?.themeColor ?? '';

  const interior_cleanliness =
    inspectionInspect?.options?.ops_options?.interior_cleanliness ?? false;
  useEffect(() => {
    setshow(false);
    if (!taskInspect) {
      props.getTask(taskId);
    }
    // eslint-disable-next-line
    return () => {
      props.resetAll();
    };
  }, []);
  useEffect(() => {
    if (imgDone) {
      loadDamages();
      loadCage();
    }
  }, [imgDone, svgRef]);
  useEffect(() => {
    if (
      imgDone &&
      taskInspect &&
      ['02'].includes(taskInspect.photoCode) &&
      !loadInterior &&
      interior_cleanliness
    ) {
      const interiorInspected =
        taskInspect?.inspectData?.interior_cleanliness ?? [];
      const data = {
        damage_group: 'INTERIOR_02',
      };
      let list = [];
      getInteriorData(data).then((res) => {
        list = res;
        const dataInterior = [];
        list = list.map((item, index) => {
          const indexInterior = interiorInspected.findIndex(
            (interiorItem) => interiorItem.component === item.component,
          );
          let data = {};

          if (indexInterior !== -1) {
            if (!interiorInspected[indexInterior].uuid) {
              data.uuid = uniqid();
            }
            const offsetWidth = $('.App-zoom').width();
            const offsetHeight = $('.App-zoom').height();

            const { x, y } = caculatePostionShow(
              offsetWidth,
              offsetHeight,
              interiorInspected[indexInterior],
            );
            data.damage_location = {
              x: x,
              y: y,
            };
            data.actual_image = {
              width: offsetWidth,
              height: offsetHeight,
            };
            data = {
              ...interiorInspected[indexInterior],
              ...data,
            };
          } else {
            data.uuid = uniqid();
          }
          dataInterior.push({
            ...data,
            ics_id: index + 1,
            component: item.component,
          });
          return {
            ...data,
            ...item,
          };
        });

        setlistInterior(list);
        setdataInspect({
          ...dataInspect,
          interior_cleanliness: [...dataInterior],
        });
        setloadInterior(true);
      });
    }
  }, [loadInterior, taskInspect, imgDone]);
  useEffect(() => {
    loadImage();
    setshowBtnFinish(true);
    setshowInspectInfo(true);
    if (adminTask.message === 'Success!') {
      setwaitting(false);
      setshowBtnFinish(false);
      setshowInspectInfo(false);
      props.resetAll();
      props.hideModal();
    } else if (unauthorized === 'Unauthorized' || error === 400) {
      setmodalErr(true);
    }
    // eslint-disable-next-line
  }, [
    error,
    adminTask.message,
    unauthorized,
    taskInspect,
    adminTask.statusTask,
  ]);

  let resetError = () => {
    props.resetAll();
    props.hideModal();
  };
  let reloadData = () => {
    loadDamages();
    loadCage();
  };
  let onZoomChange = (zoom) => {
    setzoom(zoom);
  };
  let acceptImage = () => {
    let data = {};
    setshowConfirm(false);
    let offsetWidth = $('.App-zoom').width();
    let offsetHeight = $('.App-zoom').height();
    if (
      taskInspect &&
      ['04', '05', '07', '08'].includes(taskInspect.photoCode)
    ) {
      data['message'] = btnConfirm.name;
      data['cageInfo'] = {
        original_image: {
          width: imgW,
          height: imgH,
        },
        actual_image: {
          width: offsetWidth,
          height: offsetHeight,
        },
        cage: {
          width: cagePo.width,
          height: cagePo.height,
          x: cagePo.x,
          y: cagePo.y,
          cellSize: cagePo.cellSize,
          columns: cagePo.columns,
          rows: cagePo.rows,
        },
        cageSvg: cageSvg,
      };
    } else {
      data['message'] = btnConfirm.name;
    }
    setwaitting(true);
    props.acceptImageRequest(data, taskId);
  };
  let rejectImage = () => {
    const data = {};
    data['message'] = btnConfirm.name;
    setwaitting(true);
    setshowConfirm(false);
    props.rejectImageRequest(data, taskId);
  };
  //FinishImage
  let finishImage = () => {
    const damage_table = inspectionInspect?.options?.damage_table ?? null;

    setrunOneTime(true);
    setrunOneTimeFinish(true);
    if (!adminTask) return false;
    $('.btn-reset').trigger('click');
    setshowConfirm(false);
    if (
      taskInspect &&
      taskInspect.photoCode === '02' &&
      interior_cleanliness &&
      dataInspect &&
      dataInspect.interior_cleanliness
    ) {
      dataInspect.interior_cleanliness = dataInspect.interior_cleanliness.map(
        (item) => {
          let dataUndefined = {};
          if (!item.coodrs) {
            const offsetWidth = $('.App-zoom').width();
            const offsetHeight = $('.App-zoom').height();
            const normalizedX = parseFloat(
              Number(offsetWidth / 2 / offsetWidth).toFixed(4),
            );
            const normalizedY = parseFloat(
              Number(offsetHeight / 2 / offsetHeight).toFixed(4),
            );

            dataUndefined = {
              coodrs: {
                x: normalizedX,
                y: normalizedY,
              },
              damage_location: {
                x: Number(offsetWidth / 2).toFixed(4),
                y: Number(offsetHeight / 2).toFixed(4),
              },
              actual_image: {
                width: parseFloat(Number(offsetWidth).toFixed(4)),
                height: parseFloat(Number(offsetHeight).toFixed(4)),
              },
            };
          }
          if (!item.damage_name) {
            const indexListInterior = listInterior.findIndex(
              (itemInterior) => itemInterior.component === item.component,
            );
            if (indexListInterior !== -1) {
              dataUndefined = {
                ...dataUndefined,
                ...listInterior[indexListInterior]?.list[0],
              };
            }
          }
          return { ...item, ...dataUndefined };
        },
      );
    }

    let data = { inspectData: dataInspect ? { ...dataInspect } : null };
    if (damagesInspected && damagesInspected.length > 0) {
      let edit_damages = [];
      for (let i = 0; i < damagesInspected.length; i++) {
        if (!damagesInspected[i].damage_name) {
          setmessWarning('Please Select Component Or Damage');
          setwaitting(false);
          setshowWarning(true);
          return;
        }
        if (
          ['reject', 'edit', 'revert'].includes(
            damagesInspected[i].admin_response,
          ) &&
          ((Number.isInteger(damagesInspected[i].ai_damage_id) &&
            damagesInspected[i].added) ||
            !Number.isInteger(damagesInspected[i].ai_damage_id))
        ) {
          edit_damages.push({
            ...damagesInspected[i],
            label: damagesInspected[i].des || damagesInspected[i].description,
            type: damagesInspected[i].ai_damage_id
              ? DAME_TYPES.AI
              : DAME_TYPES.MANUAL,
          });
        }
      }
      const editDamages = cloneDeep(edit_damages);
      editDamages.forEach((item) => delete item.damage_list);
      data['edit_damages'] = editDamages;
    }
    if (damages && damages.length > 0) {
      let inspectDamages = [];
      for (let i = 0; i < damages.length; i++) {
        if (damages[i].component && damages[i].damage_name) {
          inspectDamages.push({
            original_image: {
              width: imgW,
              height: imgH,
            },
            ...damages[i],
            label: damages[i].des || damages[i].description || damages[i].label,
            description:
              damage_table === 'autograde' && damages[i].condition
                ? damages[i].condition
                : damages[i].description
                ? damages[i].description
                : '',
          });
        }
      }
      data['detected_damages'] = inspectDamages;
    }
    const detectedDamages = cloneDeep(
      damagesInspected
        .filter((item) => !item.added && Number.isInteger(item.ai_damage_id))
        .map((x) => ({
          ...x,
          added: true,
          type: x.ai_damage_id ? DAME_TYPES.AI : DAME_TYPES.MANUAL,
        })),
    );
    detectedDamages.forEach((item) => delete item.damage_list);
    data['detected_damages'] = (data['detected_damages'] || []).concat(
      detectedDamages,
    );
    props.finishTask(data, taskId);
    setwaitting(true);
  };
  //
  let setScaleDown = () => {
    setdisableDragging(true);
    setscale(4);
  };
  let setScaleUp = () => {
    if (taskInspect && taskInspect.photoCode === '01') {
      setdisableDragging(false);
    } else {
      $('.btn-reset').trigger('click');
      setdisableDragging(false);
      setscale(1);
    }
  };
  // RND
  let resizeTop = (width, height, x, y) => {
    let svg = document.getElementsByClassName('svg svg');
    let svgWidth = parseInt(svg[0].getAttribute('width'), 10);
    let ratioCell = parseInt(width, 10) / svgWidth;
    if (x && y) {
      setcagePo({
        x: x,
        y: y,
        width: parseInt(width, 10),
        height: parseInt(height, 10),
        cellSize: Math.ceil(20 * ratioCell),
        columns: cagePo.columns,
        rows: cagePo.rows,
      });
    } else {
      setcagePo({
        ...cagePo,
        cellSize: Math.ceil(20 * ratioCell),
        width: parseInt(width, 10),
        height: parseInt(height, 10),
      });
    }
  };
  const resizeXY = (cagePos) => {
    setcagePo({ ...cagePo, x: cagePos.x, y: cagePos.y });
  };
  const resizeXYDamage = (index, x, y, damages, interior) => {
    let offsetWidth = $('.App-zoom').width();
    let offsetHeight = $('.App-zoom').height();

    // Get the original damage location and image dimensions
    const actualImageW = offsetWidth || 0;
    const actualImageH = offsetHeight || 0;

    // Calculate the scaling ratios
    const ratioX = Number(offsetWidth / actualImageW).toFixed(4);
    const ratioY = Number(offsetHeight / actualImageH).toFixed(4);
    const originalDamage = damages[index];
    // Convert the new position back to relative coordinates
    const relativeX = parseFloat(Number(x / ratioX).toFixed(4));
    const relativeY = parseFloat(Number(y / ratioY).toFixed(4));
    // Calculate normalized coordinates (between 0 and 1)
    const normalizedX = parseFloat(
      Number((relativeX + 11) / offsetWidth).toFixed(4),
    );
    const normalizedY = parseFloat(
      Number((relativeY + 11) / offsetHeight).toFixed(4),
    );
    let data = {
      ...originalDamage,
      x: relativeX,
      y: relativeY,
      damage_location: {
        x: relativeX, // Relative position for storage
        y: relativeY, // Relative position for storage
      },
      coodrs: {
        //  spelling wrong coords
        x: normalizedX, // Normalized x coordinate (0-1)
        y: normalizedY, // Normalized y coordinate (0-1)
      },
      actual_image: {
        width: offsetHeight,
        height: offsetHeight,
      },
      changePosition: true,
      width: Math.floor(imgW),
      height: Math.floor(imgH),
    };
    // Update the damage position with both absolute and relative coordinates

    if (!interior) {
      damages[index] = {
        ...data,
        admin_response: 'edit',
      };
      setdamagesInspected([...damages]);
    } else {
      damages[index] = {
        ...data,
      };
      setdataInspect({
        ...dataInspect,
        interior_cleanliness: [...damages],
      });
    }
  };
  //Damges
  const addDamages = (damages) => {
    setdamages([...damages]);
  };
  const updateDamage = (damages) => {
    setdamages([...damages]);
  };
  const deleteDamage = (damages) => {
    setdamages([...damages]);
  };
  const updateDamagesInspected = (damages) => {
    setdamagesInspected([...damages]);
  };

  //SVG LOAD
  let OnOfSvg = (keyName) => {
    if (keyName === 'q' && cageShow === true) {
      svgOff();
      setcageShow(!cageShow);
    } else if (keyName === 'q' && cageShow === false) {
      svgDefault();
      setcageShow(!cageShow);
    }
  };
  let svgDefault = () => {
    const pathId = $('path');
    let arrPathId = [];
    //$('#cell').css('opacity', 0.3);
    $('svg image').css('opacity', 0.7);
    for (let i = 0; i < pathId.length; i++) {
      arrPathId.push(pathId[i].id);
    }
    for (let i = 0; i < arrPathId.length; i++) {
      if (arrPathId[i]) {
        $('#' + arrPathId[i]).css('opacity', 0.5);
      }
    }
  };
  let svgOff = () => {
    const pathId = $('path');
    //$('#cell').css('opacity', 0);
    $('svg image').css('opacity', 0);
    let arrPathId = [];
    for (let i = 0; i < pathId.length; i++) {
      arrPathId.push(pathId[i].id);
    }
    for (let i = 0; i < arrPathId.length; i++) {
      if (arrPathId[i]) {
        $('#' + arrPathId[i]).css('opacity', 0);
      }
    }
  };
  let loadImage = () => {
    const urlDynamic = process.env.REACT_APP_PAVE_DYNAMIC_PHOTO_ROOT;
    const original = taskInspect?.url_builder?.o ?? null;
    const bucket = taskInspect?.url_builder?.b ?? null;

    const linkUrlPhoto =
      taskInspect && taskInspect.url_builder
        ? urlDynamic + `?` + `&b=${bucket}` + `&o=${original}`
        : taskInspect && taskInspect.image_cap
        ? taskInspect.image_cap
        : null;
    const imgLoading = new Image();
    imgLoading.src = linkUrlPhoto;
    setimg(`${linkUrlPhoto}`);
    if (linkUrlPhoto) {
      imgLoading.onload = () => {
        setimgW(imgLoading.width);
        setimgH(imgLoading.height);
        setshowImgInspect(true);
        setimgDone(true);
      };
    }
  };
  let loadCage = () => {
    const aiData = taskInspect?.aiData ?? null;
    const car_bbox = aiData?.car_bbox ?? [];
    let offsetWidth = $('.App-zoom').width();
    let offsetHeight = $('.App-zoom').height();
    let xMin = 0;
    let yMin = 0;
    let width = 0;
    let height = 0;
    if (car_bbox && car_bbox.length > 0) {
      xMin = car_bbox[0][0] * offsetWidth;
      const xMax = car_bbox[1][0] * offsetWidth;
      yMin = car_bbox[0][1] * offsetHeight;
      const yMax = car_bbox[1][1] * offsetHeight;
      width = Math.abs(xMax - xMin);
      height = Math.abs(yMax - yMin);
      setcagePo({
        x: xMin,
        y: yMin,
        width: width,
        height: height,
      });
      setshowAICage(true);
    } else {
      if (svgRef.current) {
        setTimeout(() => {
          width = svgRef?.current?.state?.element?.props?.width;
          height = svgRef?.current?.state?.element?.props?.height;
          setcagePo({
            x: xMin,
            y: yMin,
            width: width,
            height: height,
          });
        }, 1000);
      }
    }
  };
  let loadDamages = () => {
    let finalDamagesInspected = [];
    const damagesInspected = taskInspect?.inspectData?.detected_damages ?? [];
    // const damagesInteriorInspected =
    //   taskInspect?.inspectData?.interior_cleanliness ?? [];
    const offsetWidth = $('.App-zoom').width();
    const offsetHeight = $('.App-zoom').height();
    if (damagesInspected.length > 0) {
      for (let i = 0; i < damagesInspected.length; i++) {
        const item = damagesInspected[i];
        if (item.status === 'repaired') {
          continue;
        }
        if (item.admin_response !== 'reject') {
          const { x, y } = caculatePostionShow(offsetWidth, offsetHeight, item);
          const actual_image =
            item.item?.actual_image || damagesInspected[0]?.actual_image;
          finalDamagesInspected.push({
            ...damagesInspected[i],
            x: x,
            y: y,
            admin_response: false,
            actual_image,
          });
        }
      }
      setdamagesInspected([...finalDamagesInspected]);
    }
  };
  const showModalBtn = (title, name, des) => {
    setshowConfirm(true);
    setbtnConfirm({
      title: title,
      name: name,
      des: des,
    });
  };
  let onModalImage = (item, index) => {
    setsupport({ support: item, index: index });
    setmodalImage(true);
  };
  let offModalImage = () => {
    setsupport({ support: '', index: 0 });
    setmodalImage(false);
  };
  return (
    <div className="InspectionBoard">
      <div
        className="Actions"
        style={
          checkTest
            ? { background: '#ffc107' }
            : { background: `${themeColor}` }
        }
      >
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
            {/* Check Photo Full and Thumb */}
            <div className="VehiclePhoto">
              {!showImgInspect ? (
                <PhotoThumb />
              ) : (
                <PhotoFull
                  svgRef={svgRef}
                  disableDragging={disableDragging}
                  user={auth.user}
                  taskInspect={taskInspect}
                  inspectionInspect={inspectionInspect}
                  img={img}
                  imgW={imgW}
                  imgH={imgH}
                  imgDone={imgDone}
                  vehicle={vehicle}
                  svg={cageSvg}
                  cagePo={cagePo}
                  damages={damages}
                  setshowSvg={() => setshowSvg(true)}
                  showSvg={showSvg}
                  rotate={rotate}
                  zoom={zoom}
                  scale={scale}
                  setScaleDown={setScaleDown}
                  setScaleUp={setScaleUp}
                  resizeTop={(width, height) => resizeTop(width, height)}
                  damagesInspected={damagesInspected}
                  resizeXY={(cagePos) => resizeXY(cagePos)}
                  resizeXYDamage={resizeXYDamage}
                  OnOfSvg={OnOfSvg}
                  addDamages={(damages) => addDamages(damages)}
                  updateDamage={(damages) => updateDamage(damages)}
                  deleteDamage={(damages) => deleteDamage(damages)}
                  updateDamagesInspected={(damages) =>
                    updateDamagesInspected(damages)
                  }
                  setRotate={() => setrotate(rotate + 90)}
                  onZoomChange={(zoom) => onZoomChange(zoom)}
                  reloadData={reloadData}
                  onModalImage={onModalImage}
                  offModalImage={offModalImage}
                  onModalImageFull={() => setmodalImageFull(true)}
                  offModalImageFull={() => setmodalImageFull(false)}
                  onModalCheckSameInspection={() =>
                    setmodalCheckSameInspection(true)
                  }
                  offModalCheckSameInspection={() =>
                    setmodalCheckSameInspection(false)
                  }
                  listDamages={listDamages}
                  getDamages={props.getDamages}
                  clearlistDamages={props.clearlistDamages}
                  imgSupport={imgSupport}
                  support={support}
                  dataInspect={dataInspect}
                  setmodalShowTimeOutSession={() =>
                    setmodalShowTimeOutSession(false)
                  }
                  showAIDamage={showAIDamage}
                  showAICage={showAICage}
                  finishImage={finishImage}
                  runOneTimeFinish={runOneTimeFinish}
                  setrunOneTimeFinish={setrunOneTimeFinish}
                  setshowBtnFinish={setshowBtnFinish}
                  showDamages={showDamages}
                  setshowDamages={() => setshowDamages(!showDamages)}
                  listInterior={listInterior}
                  currentIndexInterior={currentIndexInterior}
                  setcurrentIndexInterior={setcurrentIndexInterior}
                  setdataInspect={setdataInspect}
                />
              )}
            </div>
          </div>
          <div
            className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3"
            style={{ background: '#e9e9ed', borderRadius: '5px' }}
          >
            {/* Inspect Photo */}
            <div className="inspect-photo">
              {/* Damage View */}
              <TitleInspect
                taskSettings={taskSetting}
                taskInspect={taskInspect}
                inspectionInspect={inspectionInspect}
              />
              <InfoBar
                inspectionInspect={inspectionInspect}
                vehicle={vehicle}
                finishImage={finishImage}
                taskInspect={taskInspect}
                imgSupport={imgSupport}
                support={support}
                runOneTime={runOneTime}
                showAIDamage={showAIDamage}
                showAICage={showAICage}
                setshowAIDamage={() => setshowAIDamage(!showAIDamage)}
                setrunOneTime={() => setrunOneTime(true)}
                modalShowTimeOutSession={modalShowTimeOutSession}
                setmodalShowTimeOutSession={() =>
                  setmodalShowTimeOutSession(true)
                }
                auth={auth}
              />
              {showBtnFinish && (
                <FinishButton
                  showModalBtn={(title, name, des) =>
                    showModalBtn(title, name, des)
                  }
                />
              )}
              {showInspectInfo && (
                <Inspect
                  getDamages={props.getDamages}
                  setlistInterior={setlistInterior}
                  listInterior={listInterior}
                  loadInterior={loadInterior}
                  currentIndexInterior={currentIndexInterior}
                  setcurrentIndexInterior={setcurrentIndexInterior}
                  getlistTrims={props.getlistTrims}
                  dataInspect={dataInspect}
                  setdataInspect={setdataInspect}
                  inspectionInspect={inspectionInspect}
                  taskSetting={taskSetting}
                  vehicle={vehicle}
                  taskInspect={taskInspect}
                  getDamagesTire={props.getDamagesTire}
                  sessionId={sessionId}
                  listDamages={listDamages}
                  damagesInspected={damagesInspected}
                  updateDamage={(damages) => updateDamage(damages)}
                  damages={damages}
                />
              )}
              <div className="checkAcp">
                {waitting ? <ModalWatting /> : null}
                {modalImage ? <ModalImage support={support} /> : null}
                {modalImageFull ? (
                  <ModalImageFull
                    src={imgSupport}
                    photoCode={taskInspect.photoCode}
                  />
                ) : null}
                {modalErr ? (
                  <ModalShowErr resetError={() => resetError()} />
                ) : null}
                {show ? <ModalCheckAdmin /> : null}
                {showConfirm ? (
                  <Modal
                    clickFinish={() => finishImage()}
                    clickAccept={() => acceptImage()}
                    clickReject={() => rejectImage()}
                    title={btnConfirm.title}
                    name={btnConfirm.name}
                    description={btnConfirm.des}
                    clickNo={() => setshowConfirm(false)}
                  />
                ) : null}
                {showWarning ? (
                  <ModalShowErr
                    messErr={messWarning}
                    resetError={() => setshowWarning(false)}
                  />
                ) : null}
                {modalCheckSameInspection ? (
                  <ModalCheckSameInspection
                    task={sameTask}
                    vehicle={vehicle}
                    hideModal={() => setmodalCheckSameInspection(false)}
                  />
                ) : null}
                <ModalMD
                  show={modalShowTimeOutSession}
                  title="Time Out"
                  body={
                    <div className="main-content">
                      <div className="form-group">
                        <div className="text-center">
                          <SVG
                            src={'/img/svg/oops.svg'}
                            uniquifyIDs={false}
                          ></SVG>
                          <span
                            className="text-head"
                            style={{ fontSize: '20px' }}
                          >
                            Plz Finish Hurry !!! If you don't want to miss Data
                          </span>
                        </div>
                      </div>
                      <div className="btn-group float-right">
                        <button
                          className="btn btn-warning"
                          onClick={() => finishImage()}
                        >
                          <span
                            className="text-bold"
                            style={{ fontSize: '14px' }}
                          >
                            Yes
                          </span>
                        </button>
                      </div>
                    </div>
                  }
                  handleClose={() => setmodalShowTimeOutSession(false)}
                  sizeModal="modal-md"
                />
              </div>
              <DamageView
                inspectionInspect={inspectionInspect}
                showSvg={showSvg}
                svg={cageSvg}
                setshowSvg={() => setshowSvg(false)}
                damagesInspected={damagesInspected}
                vehicle={vehicle}
                addDamages={(damages) => addDamages(damages)}
                updateDamage={(damages) => updateDamage(damages)}
                deleteDamage={(damages) => deleteDamage(damages)}
                updateDamagesInspected={(damages) =>
                  updateDamagesInspected(damages)
                }
                damages={damages}
                taskInspect={taskInspect}
                listDamages={listDamages}
                getDamages={props.getDamages}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  inspection: state.inspect,
});
export default connect(mapStateToProps, {
  statusStart,
  statusWaitting,
  finishTask,
  logout,
  timeOutTask,
  getTask,
  resetAll,
  getDamages,
  clearlistDamages,
  getDamagesTire,
  acceptImageRequest,
  rejectImageRequest,
  clearMessageTask,
  getlistTrims,
  getInteriorData,
})(Process);
