import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from '../../auth/operations';
import ModalShowErr from '../../inspect/containers/components/ModalShowErr';
const Sidebar = (props) => {
  const [activeSubPages] = useState({
    agentView: 'agents',
    inspectionView: 'inspections',
    cageCreate: 'cages',
    cageView: 'cages',
  });
  const [showmodalErr, setshowmodalErr] = useState(false);
  const [messErr, setmessErr] = useState('');
  const [active, setactive] = useState({});
  const [menu] = useState([
    {
      id: 'dashboard',
      path: '/dashboard',
      icon: 'fa-tasks',
      label: 'Dashboard',
    },
    {
      id: 'agents',
      path: '/agents',
      icon: 'fa-user-friends',
      label: 'Agents',
    },
    {
      id: 'inspections',
      path: '/inspections',
      icon: 'fa-clipboard-list',
      label: 'Inspections',
    },
    {
      id: 'inspect',
      path: '/inspect',
      icon: 'fa-search',
      label: 'Inspect',
    },
    {
      id: 'performance',
      path: '/performance',
      icon: 'fa-exclamation-circle',
      label: 'Performance',
    },
    {
      id: 'cages',
      path: '/cages',
      icon: 'fa-car',
      label: 'Cages',
    },
    {
      id: 'settings',
      path: '/settings',
      icon: 'fa-cogs',
      label: 'Settings',
    },
  ]);
  const { user } = props.auth;
  const role = user.role ? user.role : 'VIEWER';
  const sex = user.sex ? user.sex : 'Female';
  const totalTaskFailed = user.totalTaskFailed ? user.totalTaskFailed : false;
  const adminVer = user.adminVer ? user.adminVer : '';
  const img =
    sex === 'Male'
      ? '/img/avatar/male.svg'
      : sex === 'Female'
      ? '/img/avatar/female.svg'
      : sex === 'Other'
      ? '/img/avatar/lgbt.svg'
      : '';

  const onLogoutClick = (e) => {
    e.preventDefault();
    props.logout();
  };
  const setActive = (id) => {
    setactive({ [id]: 'active' });
  };
  useEffect(() => {
    if (adminVer) {
      const idVersion = document.getElementById('version');
      if (idVersion) {
        const version = idVersion.getAttribute('value');
        if (version !== adminVer) {
          setmessErr(
            `New Version Plz Ctrl + Shift + R (Window) / Cmd + Shift R (Mac) to update the Newest Version ${adminVer}`,
          );
          setshowmodalErr(true);
        }
      }
    }
  }, [adminVer]);
  useEffect(() => {
    let accessPath = props.location.pathname.split('/')[1];
    if (!accessPath) {
      accessPath = 'inspections';
    }
    if (activeSubPages[accessPath]) {
      accessPath = activeSubPages[accessPath];
    }
    setactive({ [accessPath]: 'active' });
  }, []);

  return (
    <div id="Navbar">
      <nav className="navbar navbar-expand-lg navbar-dark">
        <img
          src="/img/logo_white.png"
          width="auto"
          height="32"
          alt="PAVE.admin"
        />
        <div className="collapse navbar-collapse" id="NavbarItems">
          <ul className="navbar-nav" id="listMenu">
            {menu.map((item) => {
              if (
                item.id === 'performance' &&
                ['ssouthin', 'jameel.ghata', 'southin', 'steve.agent'].includes(
                  user.username,
                )
              ) {
                return null;
              }
              if (item.id === 'inspect') {
                if (!['AGENT', 'QA'].includes(role)) {
                  return null;
                }
                if (role === 'QA') {
                  item.label = 'CHECK';
                }
              }
              return (
                <li key={`Sidebar_menu_${item.id}`} className="nav-item">
                  <Link
                    to={item.path}
                    className={`nav-link ${active[item.id]}` || ''}
                    onClick={() => {
                      setActive(item.id);
                    }}
                  >
                    <i className={`fas fa-lg ${item.icon}`} />
                    <span>{item.label}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
      <button
        className={` nav-item btn nav-link text-danger`}
        style={{
          position: 'absolute',
          fontWeight: 'bold',
          right: '10px',
          top: '6px',
          color: '#9a9ab1',
          textAlign: 'center',
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
        onClick={onLogoutClick}
      >
        <i className="fas fa-lg fa-sign-out-alt" />
        <span
          style={{
            margin: '0',
            paddingTop: '10px',
          }}
        >
          Sign Out
        </span>
      </button>
      <div
        className="ver-check d-flex"
        style={{
          position: 'absolute',
          fontWeight: 'bold',
          right: '120px',
          top: '20%',
          height: '60%',
          color: '#fff',
          fontSize: '15px',
          alignItems: 'center',
          borderRadius: '5px',
          paddingRight: '25px',
        }}
      >
        <div
          className="image"
          style={{
            width: '60px',
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <div className="avatar">
            {img && (
              <img
                src={img}
                style={{
                  width: '85%',
                  background: '#fff',
                  borderRadius: '15px',
                  padding: '2px',
                }}
                alt="sex"
              />
            )}
          </div>
          {totalTaskFailed && (
            <span
              className={`noti ${
                totalTaskFailed > 15 ? 'ping-totalTaskFailed' : ''
              } text-white`}
              style={{
                position: 'absolute',
                left: '-20px',
                top: '0px',
                background: 'red',
                width: '28px',
                height: '28px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '8px',
                fontSize: '12px',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}
            >
              {totalTaskFailed > 15 && (
                <div
                  className="box-mess"
                  style={{
                    left: '-150px',
                    top: '0px',
                    position: 'absolute',
                    display: 'flex',
                    textAlign: 'center',
                    alignItems: 'center',
                    width: '140px',
                    height: '45px',
                    background: '#fff',
                    borderRadius: '10px',
                    color: '#000',
                    fontSize: '11px',
                  }}
                >
                  <div
                    className="triange"
                    style={{
                      position: 'absolute',
                      top: '0',
                      right: '-5px',
                      width: '0',
                      height: '0',
                      borderStyle: 'solid',
                      borderWidth: '30px 15px 0 0',
                      borderColor:
                        '#ffffff transparent transparent transparent',
                    }}
                  ></div>
                  You have too many priorities Plz solve it
                </div>
              )}

              {totalTaskFailed}
            </span>
          )}
        </div>
        <div>
          <span
            className="text-head"
            style={{
              display: 'block',
              textTransform: 'uppercase',
              fontWeight: 'bold',
            }}
          >
            {user.username}
          </span>
          <span
            style={{ display: 'block', fontWeight: 'bold' }}
            className={
              user.role !== 'AGENT'
                ? 'text-danger text-head'
                : 'text-success text-head'
            }
          >
            {user.role}
          </span>
        </div>
        <span id="version" value="25-03" className="ml-4 text-head">
          Ver 25-03
        </span>
      </div>
      {showmodalErr ? (
        <ModalShowErr messErr={messErr} resetError={() => props.logout()} />
      ) : null}
    </div>
  );
};

Sidebar.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  ui: state.ui,
  inspection: state.inspection,
});

export default connect(mapStateToProps, { logout })(Sidebar);
