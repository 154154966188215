import React, { useRef, useState } from 'react';
import PrismaZoom from 'react-prismazoom';
import SVG from 'react-inlinesvg';
import reactCSS from 'reactcss';
import '../../../../_sass/select-search.scss';
import Hotkeys from 'react-hot-keys';
import $ from 'jquery';
import Select from 'react-select';
import { Rnd } from 'react-rnd';
import Testing from '../../../ui/containers/Testing';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { genPhoto } from '../../../../utils/photos';
window.$ = $;
const PhotoFull = (props) => {
  let prismaZoom = useRef('prismaZoom');

  const [hoverIdDamage, setHoverIdDamage] = useState(false);
  const [showModalMaterial, setShowModalMaterial] = useState(false);
  const [chooseMaterial, setChooseMaterial] = useState(false);
  const [listMaterial, setListMaterial] = useState([
    {
      name: 'Painted',
      enable: false,
      hotKey: 'p',
      icon: 'fas fa-fill-drip',
      url: 'url(../../img/material/painted.svg),auto',
    },
    {
      name: 'Textured Surface',
      enable: false,
      hotKey: 'o',
      icon: 'fas fa-recycle',
      url: 'url(../../img/material/surface.svg),auto',
    },
    {
      name: 'Chrome',
      enable: false,
      hotKey: 'i',
      icon: 'far fa-gem',
      url: 'url(../../img/material/chrome.svg),auto',
    },
    {
      name: 'Steel',
      enable: false,
      hotKey: 'u',
      icon: 'fas fa-dharmachakra',
      url: 'url(../../img/material/steel.svg),auto',
    },
    {
      name: 'Alloy Wheel',
      enable: false,
      hotKey: 'y',
      icon: 'fas fa-drum-steelpan',
      url: 'url(../../img/material/alloy.svg),auto',
    },
    {
      name: 'Plastic Cover',
      enable: false,
      hotKey: 'j',
      icon: 'fas fa-record-vinyl',
      url: 'url(../../img/material/plastic.svg),auto',
    },
    {
      name: 'Steel Wheel',
      enable: false,
      hotKey: 'k',
      icon: 'fas fa-dumbbell',
      url: 'url(../../img/material/steelWheel.svg),auto',
    },
    {
      name: 'Glass',
      enable: false,
      hotKey: 'l',
      icon: 'fas fa-glass-whiskey',
      url: 'url(../../img/material/glass.svg),auto',
    },
  ]);
  const modalMaterial = () => {
    return (
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: '9999',
          borderRadius: '5px',
        }}
      >
        <div
          className="tool-mate"
          style={{
            display: 'flex',
            width: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#e9e9ed',
            borderRadius: '5px',
          }}
        >
          {listMaterial.map((item, index) => {
            return (
              <div
                key={`mate-${index}`}
                style={{
                  fontSize: '20px',
                  cursor: 'pointer',
                  width: '100%',
                  textAlign: 'center',
                  color: item.enable ? '#fff' : '',
                  background: item.enable ? '#321640' : '',
                  borderRadius: '5px',
                  padding: '5px 0',
                }}
                onClick={() => selectMaterial(item.name, true)}
              >
                <i className={`${item.icon}`}></i>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const onToggle = () => {
    selectMaterial('');
    setChooseMaterial(false);
  };
  const toolMaterial = () => {
    return (
      <div
        className="tool-mate"
        style={{
          float: 'right',
          display: 'flex',
          flexDirection: 'column',
          width: '3%',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: '8px',
          background: '#e9e9ed',
          borderRadius: '5px',
        }}
      >
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="ChooseMaterial"
            checked={chooseMaterial}
            onChange={onToggle}
          />
          <label
            className="custom-control-label"
            htmlFor="ChooseMaterial"
          ></label>
        </div>
        {listMaterial.map((item, index) => {
          return (
            <OverlayTrigger
              key={item.name}
              placement="right"
              overlay={
                <Tooltip id={`tooltip-${item.name}`}>
                  <strong>
                    {item.name} ({item.hotKey})
                  </strong>
                </Tooltip>
              }
            >
              <div
                key={`mate-${index}`}
                style={{
                  marginBottom: '8px',
                  fontSize: '22px',
                  cursor: 'pointer',
                  width: '100%',
                  textAlign: 'center',
                  color: item.enable ? '#fff' : '',
                  background: item.enable ? '#321640' : '',
                  borderRadius: '5px',
                  padding: '5px 0',
                }}
                onClick={() => selectMaterial(item.name, true)}
              >
                <i className={`${item.icon}`}></i>
              </div>
            </OverlayTrigger>
          );
        })}
      </div>
    );
  };
  // Damage
  const updateDamage = (e, id) => {
    let { damages } = props;
    if (damages) {
      damages[id] = { ...damages[id], ...e };
      props.clearlistDamages();
      props.updateDamage(damages);
    }
  };
  const updateDamageInterior = (e, index) => {
    let { dataInspect, currentIndexInterior } = props;
    const interior_cleanliness = dataInspect?.interior_cleanliness ?? [];
    const value = e?.value ?? null;
    const issue_score = e?.issue_score ?? 0;
    const component_label_en = e?.component_label_en ?? null;
    const label = e?.label ?? null;
    const data = {
      damage_name: value,
      issue_score: issue_score,
      component_label_en: component_label_en,
      label: label,
    };
    interior_cleanliness[index] = {
      ...interior_cleanliness[index],
      ...data,
    };
    currentIndexInterior++;
    if (currentIndexInterior === interior_cleanliness.length) {
      currentIndexInterior = 0;
    }
    props.setcurrentIndexInterior(currentIndexInterior);
    props.setdataInspect({
      ...dataInspect,
      interior_cleanliness: [...interior_cleanliness],
    });
  };
  const updateDamageInspected = (e, id) => {
    let { damagesInspected } = props;
    if (damagesInspected) {
      damagesInspected[id] = {
        ...damagesInspected[id],
        ...e,
        admin_response: 'edit',
      };
      props.clearlistDamages();
      props.updateDamagesInspected(damagesInspected);
    }
  };
  const selectMaterial = (item, select) => {
    let list = listMaterial;
    for (let i = 0; i < list.length; i++) {
      const material = list[i];
      if (material.name === item) {
        list[i].enable = true;
      } else {
        list[i].enable = false;
      }
    }
    setListMaterial([...list]);
    if (select) {
      setChooseMaterial(true);
    }
  };
  let deleteDamage = () => {
    let { damages, damagesInspected } = props;
    const index =
      hoverIdDamage && hoverIdDamage.index ? hoverIdDamage.index : 0;
    const type = hoverIdDamage && hoverIdDamage.type ? hoverIdDamage.type : '';
    if (type === 'damage') {
      const index = hoverIdDamage.index;
      if (damages[index]) {
        damages.splice(hoverIdDamage.index, 1);
        props.deleteDamage(damages);
      }
    }
    if (type === 'damageInspected') {
      const rejectAdmin = document.getElementById(`reject-${index}`);
      if (rejectAdmin) {
        rejectAdmin.classList.add('show');
      }
      if (damagesInspected[index]) {
        damagesInspected[index].admin_response = 'reject';
        props.updateDamagesInspected(damagesInspected);
      }
    }
  };
  const resetZoom = () => {
    let zoom = prismaZoom.current.getZoom();
    if (zoom > 1) {
      prismaZoom.current.reset();
    }
  };

  const toolHotKey = () => {
    let {
      imgSupport,
      onModalImage,
      offModalImage,
      inspectionInspect,
      taskInspect,
    } = props;
    const damage_table = inspectionInspect?.options?.damage_table ?? null;
    const checkBmw = damage_table ? damage_table.includes('bmw') : false;
    let photoSearch01, photoSearch02;

    switch (taskInspect && taskInspect.photoCode) {
      case '04':
        photoSearch01 = '10';
        photoSearch02 = '13';
        break;
      case '05':
        photoSearch01 = '10';
        photoSearch02 = '11';

        break;
      case '07':
        photoSearch01 = '11';
        photoSearch02 = '12';
        break;
      case '08':
        photoSearch01 = '12';
        photoSearch02 = '13';
        break;
      default:
        break;
    }
    if (
      taskInspect &&
      ['04', '05', '07', '08'].includes(taskInspect.photoCode) &&
      imgSupport.length > 0
    ) {
      imgSupport = [
        ...imgSupport.filter((item) => item.photoCode === photoSearch01),
        ...imgSupport.filter((item) => item.photoCode === photoSearch02),
        ...imgSupport.filter(
          (item) =>
            item.photoCode !== photoSearch01 &&
            item.photoCode !== photoSearch02,
        ),
      ];
    }

    return (
      <div className="tool-cage" style={{ display: 'none' }}>
        {listMaterial &&
        listMaterial.length > 0 &&
        !checkBmw &&
        damage_table !== 'autograde'
          ? listMaterial.map((item) => {
              return (
                <Hotkeys
                  key={`hotmaterial_` + item.hotKey}
                  keyName={`${item.hotKey}`}
                  onKeyDown={() => selectMaterial(item.name, true)}
                />
              );
            })
          : null}
        {!checkBmw && damage_table !== 'autograde' ? (
          <Hotkeys
            keyName="tab"
            onKeyDown={() => setShowModalMaterial(true)}
            onKeyUp={() => setShowModalMaterial(false)}
          ></Hotkeys>
        ) : null}
        <Hotkeys
          keyName="esc"
          onKeyDown={() => props.setmodalShowTimeOutSession()}
        ></Hotkeys>
        <Hotkeys keyName="space" onKeyDown={() => props.setRotate()}>
          <div onClick={() => props.setRotate()}></div>
        </Hotkeys>
        <Hotkeys keyName="x" onKeyDown={() => deleteDamage()}></Hotkeys>
        <Hotkeys keyName="r" onKeyDown={() => props.reloadData()}></Hotkeys>
        <button
          type="button"
          className="btn btn-reset"
          onClick={() => resetZoom()}
        ></button>

        <Hotkeys
          keyName="s"
          onKeyDown={() => props.onModalImageFull()}
          onKeyUp={() => props.offModalImageFull()}
        ></Hotkeys>
        <Hotkeys keyName="d" onKeyDown={() => props.setshowDamages()}></Hotkeys>
        {inspectionInspect.sameInspection && (
          <Hotkeys
            keyName="c"
            onKeyDown={props.onModalCheckSameInspection}
            onKeyUp={props.offModalCheckSameInspection}
          ></Hotkeys>
        )}

        {imgSupport && imgSupport.length > 0
          ? imgSupport.map((item, index) => {
              const linkUrlPhoto = genPhoto(item);
              return (
                <Hotkeys
                  key={`Image_` + index}
                  keyName={`${index + 1}`}
                  onKeyDown={() => onModalImage(linkUrlPhoto, index + 1)}
                  onKeyUp={() => offModalImage()}
                ></Hotkeys>
              );
            })
          : null}
        <Hotkeys
          keyName="w"
          onKeyDown={() => props.setScaleDown()}
          onKeyUp={() => props.setScaleUp()}
        ></Hotkeys>
        <Hotkeys keyName="q" onKeyUp={(e) => props.OnOfSvg(e)}></Hotkeys>
      </div>
    );
  };
  const checkDamageNotAction = (listDamage, classDamage, classRnd) => {
    for (let i = 0; i < listDamage.length; i++) {
      const dama = listDamage[i];
      if (!dama.damage_name) {
        const pointRnd = document.getElementById(`${classDamage}-${i}`);
        const point = document.getElementById(`${classRnd}-${i}`);
        if (pointRnd && point) {
          pointRnd.classList.add('heartBeat');
          point.classList.add('heartBeat');
          setTimeout(() => {
            pointRnd.classList.remove('heartBeat');
            point.classList.remove('heartBeat');
          }, 1000);
        }
        return true;
      }
    }
    return false;
  };
  const addPoint = (e) => {
    const zoom = prismaZoom.current.getZoom();
    let { listInterior, currentIndexInterior, dataInspect, inspectionInspect } =
      props;
    const interior_cleanliness =
      inspectionInspect?.options?.ops_options?.interior_cleanliness ?? false;
    if (!interior_cleanliness) {
      return;
    }
    if (zoom === 1) {
      prismaZoom.current.reset();
    }
    if (zoom > 1) {
      prismaZoom.current.zoomToZone(null);
      prismaZoom.current.reset();
    }
    let offset = $('.App-zoom').offset();
    let offsetWidth = $('.App-zoom').width();
    let offsetHeight = $('.App-zoom').height();
    // add damage lấy x, y theo click và dính point với đường kính 21px
    let relX = (e.pageX - offset.left) / zoom - 10.5;
    let relY = (e.pageY - offset.top) / zoom - 10.5;
    // Calculate normalized coordinates (between 0 and 1)
    // coodrs tính gen annotated cho API vình point có đường kính 21px
    const normalizedX = parseFloat(
      Number((relX + 10.5) / offsetWidth).toFixed(4),
    );
    const normalizedY = parseFloat(
      Number((relY + 10.5) / offsetHeight).toFixed(4),
    );
    const dataInterior = dataInspect?.interior_cleanliness ?? [];

    const index = dataInterior.findIndex(
      (item) => item.component === listInterior[currentIndexInterior].component,
    );
    let data = {};
    let dataPoint = {
      coodrs: {
        x: normalizedX,
        y: normalizedY,
      },
      damage_location: {
        x: Number(relX).toFixed(4),
        y: Number(relY).toFixed(4),
      },
      actual_image: {
        width: parseFloat(Number(offsetWidth).toFixed(4)),
        height: parseFloat(Number(offsetHeight).toFixed(4)),
      },
    };
    if (index !== -1) {
      dataInterior[index] = {
        ...dataInterior[index],
        ...dataPoint,
        damage_name: null,
      };
      dataInterior[index].coodrs = {
        x: normalizedX,
        y: normalizedY,
      };
    } else {
      dataInterior.push({
        component: listInterior[currentIndexInterior]?.component ?? '',
        label: listInterior[currentIndexInterior]?.label ?? '',
        component_label_en:
          listInterior[currentIndexInterior]?.component_label_en ?? '',
        ...dataPoint,
      });
    }
    data.interior_cleanliness = [...dataInterior];
    props.setdataInspect({ ...dataInspect, ...data });
  };
  const addDamage = (e) => {
    const zoom = prismaZoom.current.getZoom();
    let { damages, getDamages, vehicle, inspectionInspect, damagesInspected } =
      props;
    const damage_table = inspectionInspect?.options?.damage_table ?? null;
    const checkBmw = damage_table ? damage_table.includes('bmw') : false;
    const model = vehicle && vehicle.model ? vehicle.model : false;
    const make = vehicle && vehicle.make ? vehicle.make : false;
    if (zoom === 1) {
      prismaZoom.current.reset();
    }
    if (zoom > 1) {
      prismaZoom.current.zoomToZone(null);
      prismaZoom.current.reset();
    }

    let offset = $('.App-zoom').offset();
    let offsetWidth = $('.App-zoom').width();
    let offsetHeight = $('.App-zoom').height();
    // add damage lấy x, y theo click và dính point với đường kính 21px
    let relX = (e.pageX - offset.left) / zoom - 10.5;
    let relY = (e.pageY - offset.top) / zoom - 10.5;
    // Calculate normalized coordinates (between 0 and 1)
    // coodrs tính gen annotated cho API vình point có đường kính 21px
    const normalizedX = parseFloat(
      Number((relX + 10.5) / offsetWidth).toFixed(4),
    );
    const normalizedY = parseFloat(
      Number((relY + 10.5) / offsetHeight).toFixed(4),
    );
    //

    let partId = e.target.getAttribute('data-name');
    let partGroup = e.target.getAttribute('group');
    let partMaterial = e.target.getAttribute('material');
    if (!partId || !partGroup) {
      return;
    }
    let checkAction = false;
    if (damages && damages.length > 0) {
      checkAction = checkDamageNotAction(damages, 'damage-item', 'rnd');
      if (checkAction) {
        return;
      }
    }
    if (damagesInspected && damagesInspected.length > 0) {
      checkAction = checkDamageNotAction(
        damagesInspected,
        'damage-item-agent',
        'rnd-agent',
      );
      if (checkAction) {
        return;
      }
    }
    const damageSource = inspectionInspect?.options?.damages_source ?? false;
    if (damage_table === 'autograde') {
      const item = {
        component: partId,
        damage_group: partGroup,
      };
      getDamages(item, damage_table);
    } else if (checkBmw) {
      const item = {
        component: partId,
        damage_group: partGroup,
        series_nme: make === 'MINI' ? 'MINI' : model,
        source: damage_table,
      };
      getDamages(item, damage_table);
    } else {
      const mate = listMaterial.find((item) => item.enable);
      let item = { component: partId, damage_group: partGroup };
      if (chooseMaterial) {
        item = {
          ...item,
          material: mate && mate.name ? mate.name : false,
        };
      } else {
        if (partMaterial) {
          item = {
            ...item,
            material: partMaterial,
          };
          selectMaterial(partMaterial);
        } else {
          switch (partGroup) {
            case 'BODY':
              selectMaterial('Painted');
              break;
            case 'PART':
              selectMaterial('Textured Surface');
              break;
            case 'GLASS':
              selectMaterial('Glass');
              break;
            default:
              break;
          }
          const mate = listMaterial.find((item) => item.enable);
          item = {
            ...item,
            material: mate && mate.name ? mate.name : null,
          };
        }
      }

      if (damageSource) {
        item = {
          ...item,
          source: damageSource,
        };
      }
      getDamages(item);
    }
    if (partId && partGroup !== 'TIRE') {
      damages.push({
        component: partId,
        damage_group: partGroup,
        coodrs: {
          x: normalizedX, // Normalized x coordinate (0-1)
          y: normalizedY, // Normalized y coordinate (0-1)
        },
        damage_location: {
          x: Number(relX).toFixed(4),
          y: Number(relY).toFixed(4),
        },
        actual_image: {
          width: parseFloat(Number(offsetWidth).toFixed(4)),
          height: parseFloat(Number(offsetHeight).toFixed(4)),
        },
      });
      props.addDamages(damages);
    }
  };
  //

  const formatInterior = ({ damage_name, issue_score }) => (
    <div style={{ display: 'flex' }}>
      <div
        className="badge badge-primary text-head text-uppercase ml-1"
        style={{
          color: issue_score === 0 ? '#fff' : '#000',
          background:
            issue_score === 0
              ? 'grey'
              : issue_score === 1
              ? 'yellow'
              : issue_score === 2
              ? 'orange'
              : issue_score >= 3
              ? 'red'
              : '',
        }}
      >
        {damage_name}
      </div>
      <div
        style={{
          color: issue_score === 0 ? '#fff' : '#000',
          background:
            issue_score === 0
              ? 'grey'
              : issue_score === 1
              ? 'yellow'
              : issue_score === 2
              ? 'orange'
              : issue_score >= 3
              ? 'red'
              : '',
        }}
        className="badge badge-primary text-head text-uppercase ml-1"
      >
        {issue_score}
      </div>
    </div>
  );
  const damagesPointInterior = (typeSelectList) => {
    const { listInterior, currentIndexInterior, dataInspect } = props;
    let listPointInterior = dataInspect?.interior_cleanliness ?? [];
    if (listPointInterior && listPointInterior.length > 0) {
      typeSelectList = listInterior[currentIndexInterior]?.list ?? [];
      typeSelectList = typeSelectList.map((item) => {
        return {
          ...item,
          value: item.damage_name,
        };
      });
      return listPointInterior.map((item, i) => {
        let onDrag = true;
        let right = false;
        if (i === currentIndexInterior) {
          onDrag = false;
        }
        if (
          item.damage_location &&
          item.damage_location.x > item.actual_image.width / 2
        ) {
          right = true;
        }
        return (
          <Rnd
            className="damage-point"
            key={`damage-interior-point-${i}`}
            style={{
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '0 0',
              backgroundSize: 'contain',
              zIndex: 999,
            }}
            position={{
              x: item?.damage_location?.x ?? 0,
              y: item?.damage_location?.y ?? 0,
            }}
            onDrag={(e, d) => {
              props.resizeXYDamage(i, d.x, d.y, listPointInterior, true);
            }}
            lockAspectRatio={true}
            enableResizing={false}
            disableDragging={onDrag}
          >
            <div className="agent-point">{i + 1}</div>
            <i
              id={`interior-damage-${i}`}
              style={{
                color:
                  item.issue_score === 0
                    ? 'grey'
                    : item.issue_score === 1
                    ? 'yellow'
                    : item.issue_score === 2
                    ? 'orange'
                    : item.issue_score >= 3
                    ? 'red'
                    : '',
              }}
              className="far fa-lg fa-dot-circle"
            ></i>
            {!item.damage_name ? (
              <Select
                options={typeSelectList}
                menuPortalTarget={document.body}
                isClearable={false}
                formatOptionLabel={formatInterior}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
                onChange={(e) => updateDamageInterior(e, i)}
                className={`react-select-damage${right ? '-left' : '-right'}`}
                classNamePrefix="react-select"
              />
            ) : null}
          </Rnd>
        );
      });
    }
  };
  const formatDetectedDamage = ({
    label,
    unit_measure,
    grade_score,
    material,
  }) => (
    <div style={{ display: 'flex' }}>
      {material ? (
        <div
          style={{ color: '#fff' }}
          className="badge badge-primary text-head text-uppercase"
        >
          {material === 'Painted' ? (
            <i className="fas fa-fill-drip"></i>
          ) : material === 'Textured Surface' ? (
            <i className="fas fa-recycle"></i>
          ) : material === 'Chrome' ? (
            <i className="far fa-gem"></i>
          ) : material === 'Alloy Wheel' ? (
            <i className="fas fa-drum-steelpan"></i>
          ) : material === 'Plastic Cover' ? (
            <i className="fas fa-record-vinyl"></i>
          ) : material === 'Steel Wheel' ? (
            <i className="fas fa-dumbbell"></i>
          ) : material === 'Glass' ? (
            <i className="fas fa-glass-whiskey"></i>
          ) : material === 'Steel' ? (
            <i className="fas fa-dharmachakra"></i>
          ) : null}
        </div>
      ) : null}
      <div
        style={{ color: '#fff' }}
        className="badge badge-primary text-head text-uppercase ml-1"
      >
        {unit_measure &&
        (unit_measure.includes('Component') ||
          unit_measure.includes('component'))
          ? 'C'
          : 'I'}
      </div>
      <div
        className="badge badge-primary text-head text-uppercase ml-1"
        style={{
          color: grade_score === 0 ? '#fff' : '#000',
          background:
            grade_score === 0
              ? 'grey'
              : grade_score === 1
              ? 'yellow'
              : grade_score === 2
              ? 'orange'
              : grade_score >= 3
              ? 'red'
              : '',
        }}
      >
        {label}
      </div>
      <div
        style={{
          color: grade_score === 0 ? '#fff' : '#000',
          background:
            grade_score === 0
              ? 'grey'
              : grade_score === 1
              ? 'yellow'
              : grade_score === 2
              ? 'orange'
              : grade_score >= 3
              ? 'red'
              : '',
        }}
        className="badge badge-primary text-head text-uppercase ml-1"
      >
        {grade_score}
      </div>
    </div>
  );
  const showImageFull = () => {
    let {
      taskInspect,
      img,
      damages,
      svg,
      cagePo,
      rotate,
      scale,
      damagesInspected,
      disableDragging,
      checkTest,
      listDamages,
      vehicle,
      inspectionInspect,
      showAIDamage,
      user,
      runOneTimeFinish,
      showDamages,
      svgRef,
    } = props;
    const aiData = taskInspect?.aiData ?? null;
    const skillsUser = user?.skills ?? [];
    let showAi = false;
    if (
      skillsUser.includes('AI') ||
      ['TRAINER', 'SUPER', 'ADMIN'].includes(user.role)
    ) {
      showAi = true;
    }
    const mate = listMaterial.find((item) => item.enable);
    const damage_table = inspectionInspect?.options?.damage_table ?? null;
    const checkBmw = damage_table ? damage_table.includes('bmw') : false;
    const timeInspect = taskInspect?.timeInspect ?? 0;
    const timeCheck = taskInspect?.timeCheck ?? 0;
    let timeCountDown = user.role === 'AGENT' ? timeInspect : timeCheck;
    const durationInspect = taskInspect?.durationInspect ?? 0;
    const durationCheck = taskInspect?.durationCheck ?? 0;
    const durationCountDown =
      user.role === 'AGENT' ? durationInspect : durationCheck;
    let realtimeCountDown = timeCountDown - durationCountDown;
    let statusTime = false;
    const styles = reactCSS({
      default: {
        img: {
          transform: `rotate(${rotate}deg)`,
        },
      },
    });
    const inspectedAt = taskInspect?.inspectedAt ?? null;
    const checkedAt = taskInspect?.checkedAt ?? null;
    let startedAt = user.role === 'AGENT' ? inspectedAt : checkedAt;

    if (startedAt && realtimeCountDown > 2) {
      startedAt = new Date(startedAt);
      if (
        ['PROCESSING'].includes(taskInspect.status) &&
        ['AGENT', 'QA'].includes(user.role)
      ) {
        statusTime = true;
      }
      startedAt = startedAt.getTime();
      const timeAuto = startedAt + realtimeCountDown * 1000;
      const now = Date.now();
      realtimeCountDown = (timeAuto - now) / 1000;
      if (['AGENT'].includes(user.role)) {
        if (realtimeCountDown < 5) {
          props.setshowBtnFinish(false);
        }
        if (realtimeCountDown < 3 && !runOneTimeFinish) {
          props.finishImage();
          props.setrunOneTimeFinish(true);
        }
      }
    }
    const uiTimerTask = () => {
      return (
        <div
          className={`timer badge badge-${
            statusTime ? 'warning' : 'dark'
          } text-head `}
          style={{
            position: 'absolute',
            right: '15px',
            top: '15px',
            fontSize: '16px',
            zIndex: 99999,
          }}
        >
          <i className={`fas fa-history ${statusTime ? 'fa-spin' : ''}`}></i>
          <span className="ml-1">{Math.floor(realtimeCountDown)} s</span>
        </div>
      );
    };
    const uiDamageAI = () => {
      const damages = aiData?.damages ?? [];
      let offsetWidth = $('.App-zoom').width();
      let offsetHeight = $('.App-zoom').height();
      return showAi && aiData
        ? damages.map((damage, index) => {
            let boxDamagePoint = {
              position: 'absolute',
              zIndex: 9999,
              color: 'yellow',
            };
            if (damage.keypoint) {
              const xMin = damage?.keypoint[0] * offsetWidth;
              const yMin = damage?.keypoint[1] * offsetHeight;
              boxDamagePoint.left = `${xMin - 11}px`;
              boxDamagePoint.top = `${yMin - 8}px`;
            }
            const label = damage['damage-label'] ?? null;
            return (
              <div key={`AIDamage-${index}`} style={boxDamagePoint}>
                {label ? (
                  <span
                    style={{
                      position: 'absolute',
                      top: '-22px',
                      left: '-15px',
                      margin: 0,
                    }}
                  >
                    {label}
                  </span>
                ) : null}
                {damage.keypoint ? (
                  <i
                    style={{ margin: 0 }}
                    className="far fa-lg fa-dot-circle"
                  ></i>
                ) : null}
              </div>
            );
          })
        : null;
    };
    let damagePointInspected = [];
    let damagePoint = [];
    let typeSelectList = [];

    const damagesAPI =
      listDamages &&
      listDamages.success &&
      listDamages.damages &&
      listDamages.damages.length
        ? listDamages.damages
        : false;
    if (damagesAPI) {
      for (let h = 0; h < damagesAPI.length; h++) {
        const damage = damagesAPI[h];
        typeSelectList.push({
          ...damage,
          damage_type: damage.damage_type
            ? damage.damage_type
            : damage.damage_name
            ? damage.damage_name
            : '',
          des: damage.label,
          label: damage.damage_name,
        });
      }
    }
    if (damagesInspected && damagesInspected.length > 0) {
      let defaultSelectIndex = damagesInspected.findIndex(
        (x) => Number.isInteger(x.ai_damage_id) && !x.damage_name,
      );
      for (let i = 0; i < damagesInspected.length; i++) {
        const item = damagesInspected[i];
        if (item.ai_damage_id && !item?.damage_list?.length) continue;
        if (item.ai_damage_id) {
          typeSelectList = item?.damage_list?.map((damage) => ({
            ...damage, damage_type: damage.damage_type
              ? damage.damage_type
              : damage.damage_name
                ? damage.damage_name
                : '',
            des: damage.label,
            label: damage.damage_name,
          })) || [];
        }

        if (item.admin_response !== 'reject') {
          let onDrag = true;

          if (inspectionInspect.sameInspection || Number.isInteger(item.ai_damage_id)) {
            onDrag = false;
          }
          let right = false;
          if (
            item.damage_location &&
            item.damage_location.x > item.actual_image?.width / 2
          ) {
            right = true;
          }
          damagePointInspected.push(
            <Rnd
              className="damage-point"
              key={`damage-agent-${i}`}
              onMouseEnter={() =>
                setHoverIdDamage({
                  index: i,
                  type: 'damageInspected',
                })
              }
              style={{
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '0 0',
                backgroundSize: 'contain',
                zIndex: 999,
              }}
              position={{
                x: item?.x ?? 0,
                y: item?.y ?? 0,
              }}
              onDrag={(e, d) => {
                props.resizeXYDamage(i, d.x, d.y, damagesInspected, false);
              }}
              lockAspectRatio={true}
              enableResizing={false}
              disableDragging={onDrag}
            >
              <div className="agent-point">A-{i + 1}</div>
              <i
                id={`rnd-agent-${i}`}
                style={{
                  color:
                    item.grade_score === 0
                      ? 'grey'
                      : item.grade_score === 1
                      ? 'yellow'
                      : item.grade_score === 2
                      ? 'orange'
                      : item.grade_score >= 3
                      ? 'red'
                      : '',
                }}
                className="far fa-lg fa-dot-circle"
              ></i>
               {!item.damage_name && (!Number.isInteger(item.ai_damage_id) || i === defaultSelectIndex) ? (
                <Select
                  options={typeSelectList}
                  menuPortalTarget={document.body}
                  menuPlacement={
                    item.y > item.actual_image?.height / 2 ? 'top' : 'bottom'
                  }
                  isClearable={false}
                  formatOptionLabel={formatDetectedDamage}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  onChange={(e) => updateDamageInspected(e, i)}
                  className={`react-select-damage${right ? '-left' : '-right'}`}
                  classNamePrefix="react-select"
                />
              ) : null}
            </Rnd>,
          );
        }
      }
    }
    for (let i = 0; i < damages.length; i++) {
      let damage = damages[i];
      let right = false;
      if (
        damage.damage_location &&
        damage.damage_location.x > damage.actual_image.width / 2
      ) {
        right = true;
      }
      damagePoint.push(
        <Rnd
          className="damage-point"
          key={`damage-point-${i}`}
          onMouseEnter={() =>
            setHoverIdDamage({
              index: i,
              type: 'damage',
            })
          }
          style={{
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '0 0',
            backgroundSize: 'contain',
            zIndex: 999,
          }}
          position={{
            x: damage?.damage_location?.x ?? 0,
            y: damage?.damage_location?.y ?? 0,
          }}
          lockAspectRatio={true}
          enableResizing={false}
          disableDragging={true}
        >
          <div className={`admin-point${!right ? '-right' : ''}`}>{i + 1}</div>
          <i
            id={`rnd-${i}`}
            style={{
              color:
                damage.grade_score === 0
                  ? 'grey'
                  : damage.grade_score === 1
                  ? 'yellow'
                  : damage.grade_score === 2
                  ? 'orange'
                  : damage.grade_score >= 3
                  ? 'red'
                  : '',
            }}
            className="far fa-lg fa-dot-circle"
          ></i>
          {!damage.damage_name ? (
            <Select
              options={typeSelectList}
              menuPortalTarget={document.body}
              classNamePrefix={
                damage.damage_location &&
                damage.damage_location.y > damage.actual_image.height / 2
                  ? 'top'
                  : 'bottom'
              }
              isClearable={false}
              formatOptionLabel={formatDetectedDamage}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
              onChange={(e) => updateDamage(e, i)}
              className={`react-select-damage${right ? '-left' : '-right'}`}

            />
          ) : null}
        </Rnd>,
      );
    }
    if (
      taskInspect &&
      ['01', '02', '03', '06', '15'].includes(taskInspect.photoCode)
    ) {
      return (
        <>
          <div className="tool-image">
            {checkTest ? <Testing /> : null}
            <div
              className="img-full"
              id="imgProcess"
              onDoubleClick={(e) => addPoint(e)}
            >
              {uiTimerTask()}
              <PrismaZoom
                className="App-zoom"
                style={{ overflow: 'scroll' }}
                minZoom={1}
                maxZoom={scale}
                leftBoundary={0}
                topBoundary={0}
                onZoomChange={(e) => props.onZoomChange(e)}
                ref={prismaZoom}
              >
                <img style={styles.img} src={img} alt="" />
                {damagesPointInterior(typeSelectList)}
              </PrismaZoom>
            </div>
            {toolHotKey()}
          </div>
          {vehicle ? (
            <div
              className="info mt-2 mb-2"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                className="group-info"
                style={{
                  background: '#fff',
                  padding: '5px 25px',
                  fontSize: '17px',
                  color: 'red',
                  borderRadius: '14px',
                  textTransform: 'uppercase',
                }}
              >
                <span className="text-head">
                  {vehicle && vehicle.year ? vehicle.year : ''}-
                  {vehicle && vehicle.make ? vehicle.make : ''}-
                  {vehicle && vehicle.model ? vehicle.model : ''}-
                  {vehicle && vehicle.bodyType ? vehicle.bodyType : ''}
                </span>
              </div>
            </div>
          ) : null}
        </>
      );
    } else {
      return (
        <div>
          <div className="tool-image" style={{ display: 'flex' }}>
            {checkTest ? <Testing /> : null}
            <div
              className="img-full"
              id="imgProcess"
              onDoubleClick={(e) => addDamage(e)}
            >
              {uiTimerTask()}
              {!checkBmw && showModalMaterial ? modalMaterial() : null}
              {toolHotKey()}
              <PrismaZoom
                className="App-zoom"
                minZoom={1}
                maxZoom={scale}
                onZoomChange={(e) => props.onZoomChange(e)}
                ref={prismaZoom}
              >
                <img style={styles.img} src={img} alt="" />
                <Rnd
                  className="rnd-cage"
                  style={{
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '0 0',
                    backgroundSize: 'contain',
                    cursor: mate && mate.url ? mate.url : '',
                  }}
                  size={{
                    width: cagePo.width,
                    height: cagePo.height,
                  }}
                  position={{
                    x: cagePo.x,
                    y: cagePo.y,
                  }}
                  onDrag={(e, d) => {
                    props.resizeXY({
                      x: d.x,
                      y: d.y,
                    });
                  }}
                  onResize={(e, direction, ref, delta, position) => {
                    const width = ref.style.width;
                    const height = ref.style.height;
                    const x = position.x;
                    const y = position.y;
                    props.resizeTop(width, height, x, y);
                  }}
                  lockAspectRatio={true}
                  disableDragging={disableDragging}
                >
                  {svg ? (
                    <SVG
                      className={'imageHolder hoverable cageSVG'}
                      src={svg}
                      uniquifyIDs={false}
                      ref={svgRef}
                      style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 99,
                      }}
                    ></SVG>
                  ) : null}
                </Rnd>
                {showDamages ? damagePoint : null}
                {showDamages ? damagePointInspected : null}
                {showAIDamage ? uiDamageAI() : null}
              </PrismaZoom>
            </div>
            {!checkBmw && damage_table !== 'autograde' ? toolMaterial() : null}
          </div>
          {vehicle ? (
            <div
              className="info mt-2 mb-2"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                className="group-info"
                style={{
                  background: '#fff',
                  padding: '5px 25px',
                  fontSize: '17px',
                  color: 'red',
                  borderRadius: '15px',
                  textTransform: 'uppercase',
                }}
              >
                <span className="text-head">
                  {vehicle && vehicle.year ? vehicle.year : ''}-
                  {vehicle && vehicle.make ? vehicle.make : ''}-
                  {vehicle && vehicle.model ? vehicle.model : ''}-
                  {vehicle && vehicle.bodyType ? vehicle.bodyType : ''}
                </span>
              </div>
            </div>
          ) : null}
        </div>
      );
    }
  };

  return <div>{showImageFull()}</div>;
};

export default PhotoFull;
